.navbar,
.bg-color {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 4rem;
  background: RGBA(0, 0, 0, 0.7);
  z-index: 1001;
  background-color: $blackback;
  padding: 1rem;
  @media screen and (max-width: 860px) and (min-width: 768px) {
   padding: 0rem;
  }
  .navbar-toggler-icon {
    @media screen and (max-width: 768px) {
      background-image: url("../../images/topnav/hamburger.svg");
    }
  }
  .nav-link:active {
    color: #b235ff;
    text-decoration: underline;
  }
  .nav-link:hover {
    color: #b235ff;
    text-decoration: underline;
  }
  .scroller {
    color: $white !important;
  }
  .scroller:active {
    color: #b235ff !important;
  }
  .scroller:hover {
    color: #b235ff !important;
  }
  .navbar-brand {
    @media screen and (max-width: 860px) and (min-width: 768px) {
      width: 6rem;
      margin-right: 0rem!important;
     }
    @media screen and (max-width: 767px) {
      width: 8rem;
    }
  }
  .logo-section {
    width: 30%;
    @media screen and (max-width: 1024px) and (min-width: 769px) {
      width: 24%!important;
    }
    .logo {
      width: 10rem;
      @media screen and (max-width: 1024px) and (min-width: 769px) {
        width: 6rem;
      }
    }
    // @media screen and (max-width: 768px) {
    //  width: 20%;

    //  }
    @include breakpoint-down(large) {
      width: 30%;
    }
    @include breakpoint-down(medium) {
      width: 20%;
    }
  }
  .navbar-collapse {
    @media screen and (max-width: 767px) {
      background-color: $blackback;
      padding: 1rem 1rem;
    }
  }
  .nav-item {
    margin-left: 1rem;
    font-size: 18px;
    @media screen and (max-width: 1440px) and (min-width: 1216px) {
      font-size: 16px;
    }
    // @media screen and (max-width: 1215px) and (min-width: 1025px) {
    //   margin-left: 0.5rem;
    //   font-size: 15px;
    // }
    @media screen and (max-width: 1330px) and (min-width: 861px) {
      font-size: 12px;
      margin-left: 0.5rem;
    }
    @media screen and (max-width: 860px) and (min-width: 768px) {
      margin-left: 0.2rem;
      font-size: 9px;
    }
    //background-color: red;
    .active-link
    {
      font-weight: bold;
      background-color: red;
    }
  }
  .join-community {
    border-radius: 5px;
    border: 1px solid $black;
    background-color: $white;
    @media screen and (max-width: 767px) {
      width: 80%;
    }
    a {
      color: black !important;
      font-weight: 600;
      text-align: center;
    }
  }
}
