html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  font-family: $Montserrat;
  line-height: 1.4;
  font-size: $font-xsm;
  //filter: contrast(101%);
  // color: $purple;
  background-color: $blackback;
}

li {
  list-style-type: none;
}

// Text
h1,
h2,
h3 {
  font-weight: 600;
  line-height: 1.15;
}

h1 {
  font-size: $font-xxl;
  margin-bottom: 1rem;
  font-weight: $bold;

  @include breakpoint-down(small) {
    font-size: $font-xl;
  }
}

h2 {
  font-size: $font-xl;
  padding: 0.5rem 0;

  @include breakpoint-down(small) {
    font-size: $font-lg;
  }
}

h3 {
  font-size: $font-lg;
  font-weight: $semiBold;
  margin-top: 0.3rem;
  @include breakpoint-down(small) {
    font-size: $font-med;
  }
}

h4 {
  font-size: 1.375rem;
  font-weight: $semiBold;
  margin-top: 1rem;
}

p {
  line-height: 1.5;
  margin-bottom: 1rem;
  font-size: 22px;
  margin-top: 1rem;
  @include breakpoint-down(large) {
    font-size: $font-xsm;
  }
  @include breakpoint-down(medium) {
    font-size: $font-xsm;
  }
  @include breakpoint-down(small) {
    font-size: $font-xsm;
  }
}

a,
A:hover,
a:visited {
  text-decoration: none!important;
  color: $white;
}

//Box Shadow
.bx {
  box-shadow: 0px 3px 6px #00000029;
}

//Border
.border {
  border: 1px solid $veryLightGray;
}

.bor {
  border-radius: 0.625rem;
}
//Image
img {
  max-width: 100%;
  height: auto;
}

// Spacing
.container {
  max-width: 70rem;
  margin: 0 auto;
}

.small-container {
  max-width: 43rem;
  margin: 0 auto;
  @include breakpoint-down(small) {
    padding: 0 1rem;
  }
}

// Flexbox
.flex {
  display: flex;

  &-jc-sb {
    justify-content: space-between;
  }
  &-jc-c {
    justify-content: center;
  }
  &-jc-sa {
    justify-content: space-around;
  }
  &-ai-c {
    align-items: center;
  }
}
// Button
button,
.button {
  cursor: pointer;
  padding: 0.78125rem 1.3125rem;
  // color: $purple !important;
  background: $white;
  border-radius: 10px;
  font-size: $font-xsm;
  font-family: inherit;
}

// Visibility
.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none;
  }
}

.hide-for-desktop {
  @include breakpoint-up(large) {
    display: none;
  }
}

// Font weights

.semibold {
  font-weight: $semiBold;
}
.bold {
  font-weight: $bold;
}

// scroller
.scroller {
  cursor: pointer;
}

.loader-screen {
  //text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50rem;
  //height: 10rem;
  //border: 1px solid red;
  //padding-bottom: 1rem;
  // img {
  //   margin-top: 8rem;
  // }
  .loader {
    margin-top: 8rem;
    position: relative;
    animation: mymove 2s infinite;
  }
  @keyframes mymove {
    from {
      left: -30px;
    }
    to {
      left: 80px;
    }
  }
  h3 {
    color: $white;
    font-family: $Montserrat;
    //font-weight: $extraBold;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  p {
    font-size: 0.7rem !important;
    font-family: $Montserrat;
    font-style: italic;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20rem;
  }
}

.slick-prev:before {
  //content: ".";
  color: transparent;
  //font-size: 30px;
  background-image: url("../images/arrows/arrowleft.svg");
  background-repeat: no-repeat;
  margin-left: 1rem;
}

.slick-next:before {
  //content: ".";
  color: transparent;
  //font-size: 30px;
  background-image: url("../images/arrows/arrow.svg");
  background-repeat: no-repeat;
  //margin-right: 1rem;
  //border: 1px solid red;
}
.slick-dots {
  margin-bottom: -50px;
  @media (max-width: 768px) {
    margin-bottom: -11px;
  }
}
.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: white;
}
@media (min-width: 768px) and (max-width: 1000px) {
  .collapse {
    display: none !important;
  }
}

.try-btn {
  .btn-s {
    background: linear-gradient(
      126.21deg,
      #6a35ee 0%,
      #9930ef 14.24%,
      #5737ee 49.56%,
      #795ceb 93.2%
    );
    border-radius: 10px;
    border: 1px solid #795ceb;
    font-size: 1.5rem;
    padding: 0.4rem 3.65rem;
    font-weight: 600;
    color: white;
    animation: zoom-in-zoom-out 2s ease-out infinite;
    //       -webkit-animation: glowing 1500ms infinite;
    // -moz-animation: glowing 1500ms infinite;
    // -o-animation: glowing 1500ms infinite;
    // animation: glowing 1500ms infinite;
  }
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
      background-color: #6a35ee;
      -webkit-box-shadow: 0 0 3px #6a35ee;
    }
    50% {
      transform: scale(1.2, 1.2);
      background-color: #9930ef;
      -webkit-box-shadow: 0 0 40px #9930ef;
    }
    100% {
      transform: scale(1, 1);
      background-color: #6a35ee;
      -webkit-box-shadow: 0 0 3px #6a35ee;
    }
  }
}
.video-back {
  z-index: 1000;
}
.line-seprator {
  border-top: 2px solid $white;
  opacity: 0.1;
}
.line-seprator-play {
  border-top: 1px solid $white;
  opacity: 0.2;
  margin: auto 0;
}
.volume-button {
  position: absolute;
  top: 77%;
  left: 3%;
  z-index: 1001;
  @media (min-width: 1401px) and (max-width: 1800px) {
    top: 77%;
  }
  @media (min-width: 1241px) and (max-width: 1400px) {
    top: 66%;
  }
  @media (min-width: 769px) and (max-width: 1240px) {
    top: 59%;
  }
  // @media (max-width: 768px)  {
  //   top: 70%;
  //  }
  @media (min-width: 375px) and (max-width: 768px) {
    top: 87%;
  }
  @media (min-width: 320px) and (max-width: 374px) {
    top: 70%;
  }
}

.modal-content {
  // background-image: url("../images//topnav/popup.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: black;
  color: white;
  border: 1px solid $white;
  width: 80rem;
  margin: 0%!important;

  @media screen and(min-width: 769px) and (max-width: 1024px) {
    width: 54rem!important;
    
}

  @media screen and (min-width:601px)and(max-width:768px) {
    width: 41rem!important;
    
  }
  @media screen and(max-width:600px) and(min-width: 476px) {
    width: 24rem!important;
    
  }
  @media screen and(max-width:475px) {
    width: 21rem!important;
    
  }

  .modal-title {
    text-align: center;
  }
  .social-img{
    position: absolute;
    left: 95%;
    top: -10%;
    //width: 3rem;

    @media screen and(max-width: 600px) {
    position: absolute;
    left: 92%;
    top: -10%;
    width: 3rem;
    }
  }
  .social-set {
    padding: 2rem;
    display: flex;
    justify-content: center;
    
    @media screen and(max-width: 600px) {
      // margin: 1rem;
      // width: 3rem;
      // flex-wrap: nowrap;

      display: flex;
    justify-content: center;
    padding: 2rem;
    flex-wrap: wrap;
    }

    img {
     margin: 1rem;
      width: 3rem;
    }
  }
}
.dropdown-menu {
  background-color: black;
}
.flag {
  margin-right: 0.5rem;
}
.games-dropdown-2 {
  background-color: black !important;
  color: white !important;
}
#react-select-5-input {
  color: white;
}
.social-job {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  .btn-m {
    display: flex;
    justify-content: center;
    width: 18rem;
    //height: 4rem;
    margin: 1rem;
    font-weight: bold;
    align-items: center;
    .icon {
      width: 2rem;
      margin-right: 1rem;
    }
    span {
      margin-top: 3px;
    }
    @media screen and (max-width: 768px) {
      // flex-direction: column;
      // align-items: center;
      width: 14rem;
    }
  }
}

.modal-dialog-centered {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
}


.modal-cross-sign
{
   position: absolute; 
   left: 93%;
    top: -14%;
    @media screen and (max-width: 475px) {
      left: 89%;
      top: -1%;
    }

}

 .nav-link.active {
 color: #b235ff;
}

.social-set-and {
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  @media screen and(max-width: 600px) {
    // margin: 1rem;
    // width: 3rem;
    // flex-wrap: nowrap;

    display: flex;
  justify-content: center;
  padding: 2rem;
  flex-wrap: wrap;
  }

  img {
   margin: 1rem;
    width: 19rem;
  }
}