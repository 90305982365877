.footer {
  //border: 1px solid red;
  padding: 4rem 5rem 2rem 5rem;
  color: $white;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    padding: 2rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 2rem;
  }
  .footer-content {
    display: flex;
    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }
  .logo {
    width: 18rem;
  }
  .logo-section {
    width: 20%;
    // border: 1px solid red;
    @media screen and (max-width: 1024px) {
      width: 100%;
      text-align: center;
    }
  }
  .quicklinks {
    width: 60%;
    padding: 0rem 7rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    //border: 1px solid red;

    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      width: 50%;
      padding: 0rem 3rem;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      text-align: center;
      padding: 0rem 3rem;
    }
    // p{
    //     // margin-right: 1.5rem;
    //     // margin-left: 1.5rem;
    //     margin: 0px 1.5rem;
    //     a{font-size: 20px;}
    // }
    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      // margin-right: 1.5rem;
      // margin-left: 1.5rem;
      margin: 0px 1.5rem;
      a {
        font-size: 22px;
      }
    }
    .download {
      .btns {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        //border: 1px solid red;
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
      h4 {
        text-align: center;
        margin-bottom: 2rem;
      }
      a {
        img {
          margin-right: 1rem;
          margin-left: 1rem;
          // margin-left: auto;
          // margin-right: auto;
          width: 14rem;
          @media (min-width: 769px) and(max-width: 1800px) {
            width: 10rem;
            //margin-right: 1rem;
            //margin-left: 1rem;
            margin-top: 1rem;
          }
          @media (max-width: 768px) {
            width: 15rem;
            margin-right: 1rem;
            margin-left: 1rem;
            margin-top: 1rem;
          }
        }
      }
    }
  }
  .socials {
    width: 20%;
    //border: 1px solid red;
    @media screen and (max-width: 1024px) {
      width: 100%;
      text-align: center;
      margin-top: 2rem;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      width: 30%;
    }

    img {
      margin-right: 1rem;
      margin-left: 1rem;
    }
    .icons {
      display: flex;
      @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: center;
      }
      img {
        width: 2rem;
        @media screen and (max-width: 768px) {
          width: 1.5rem;
        }
      }
    }
    .join-community {
      border-radius: 5px;
      border: 1px solid $black;
      background-color: $white;
      width: 85%;
      margin-top: 1rem;
      a {
        color: black !important;
        font-weight: 600;
        text-align: center;
      }
    }
    .input-group {
      // justify-content: center;
      flex-wrap: nowrap;
      @media screen and (max-width: 1024px) {
        justify-content: center;
      }

      .btn {
        // background: linear-gradient(
        //   126.21deg,
        //   #6a35ee 0%,
        //   #9930ef 14.24%,
        //   #5737ee 49.56%,
        //   #795ceb 93.2%
        // );
        border-radius: 5px;
        font-size: 0.9rem;
        width: 30%;
        padding: 0.4rem 0.65rem;
        font-weight: 600;
        color: white;
        background-color: black;
        border: 1px solid white;
        @media screen and (max-width: 1024px) and(min-width: 768px) {
          width: 20%;
        }
      }
    }
  }
  .rights {
    margin-top: 1rem;
    text-align: center;
  }
  .head-foot {
    //color: $pink;
    font-weight: 600;
  }
}
