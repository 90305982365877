.privacy {
  margin: 10rem 20rem;
  color: $white;
  //text-align: center;
  h1
   {
    text-align: center;
    margin-bottom: 5rem;
  }
  ul{
    li{
        list-style-type: square;
        //color: $pink
    }
  }
}
