$white: #ffffff;
$black: #000000;
$darkBlue: #171b3b;
$gray: #dddddd;
$veryLightGray: #dbdbdb;
$creamy: #fcfdff;
$darkGray: #f5f2f2;
$bg: #fafafa;
$footerBg: #f1f5fc;
$seagreen: #04FFF0;
$blackback:#000000;
$teal: #123239;
$bottlegreen: #123924;
$maroon:#4B1616;
$brown:#4B3C16;
$seagreen2:#159AB7;
$green2:#15B74C;
$maroon2:#B71515;
$brown2:#B7B015;
$greensubheading:#00FECF;
$pink:#9930ef;
// Fonts
$font-xsm: 1rem; //16px
$font-sm: 1.125rem; //18px
$font-med: 1.25rem; //20px
$font-lg: 1.5625rem; //25px
$font-xl: 1.875rem; //30px
// $font-xxl: 2.1875rem; //35px
$font-xxl: 3.125rem; //50px
// Font Weight
$thin: 300;
$semiBold: 600;
$medium: 500;
$bold: 700;

//font family
$Montserrat:'Montserrat', sans-serif;
$Bellota: 'Bellota Text', cursive;