.landing {
  .line-seprator {
    border-top: 2px solid $white;
    opacity: 0.1;
  }
  //moverse main//
  .video-main {
    //position: relative;
    @media screen and (max-width: 991px) {
      margin-top: 4rem;
    }

    .banner {
      @media screen and (min-width: 768px) {
        aspect-ratio: 1080/1920;
      }
    }

    .slider-images {
      .image-sec {
        position: relative;
      }
      .text-sec {
        top: 8%;
        left: 8%;
        z-index: 1;
        position: absolute;
        //border: 1px solid;
        text-align: left;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          top: 10%;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          top: 14%;
        }

        @media screen and (max-width: 992px) {
          width: 83%;
          top: 8%;
        }

        .play {
          //margin-left: 10% !important;
          font-style: normal;
          font-weight: 600;
          font-size: 26.0239px;
          line-height: 32px;
          /* identical to box height */

          letter-spacing: 0.345em;
          text-transform: uppercase;
          margin-bottom: 0px !important;
          color: #ffffff;

          @media screen and (min-width: 2000px) {
            font-size: 30.0239px;
            line-height: 32px;
            letter-spacing: 0.345em;
          }
          @media screen and (min-width: 1025px) and (max-width: 1999px) {
            font-size: 24.0239px;
            line-height: 32px;
            letter-spacing: 0.345em;
          }
          @media screen and (min-width: 376px) and (max-width: 1024px) {
            font-size: 11.7766px;
            line-height: 14px;
          }

          @media screen and (min-width: 320px) and (max-width: 375px) {
            font-size: 10.7766px;
            line-height: 0px;
          }
        }
        .main-head {
          font-style: normal;
          font-weight: 800;
          font-size: 158.648px;
          line-height: 159px;
          //text-align: center;
          text-transform: uppercase;
          margin-bottom: 0px !important;
          color: #ffffff;

          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 110.648px;
            line-height: 112px;
          }
          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 67px;
            line-height: 95px;
          }

          @media screen and (max-width: 992px) {
            font-size: 51.1222px;
            line-height: 72px;
          }

          @media screen and (min-width: 320px) and (max-width: 375px) {
            font-size: 44.1222px;
            line-height: 62px;
          }
        }
        .world {
          font-style: normal;
          font-weight: 800;
          font-size: 204.436px;
          line-height: 249px;
          //text-align: center;
          text-transform: uppercase;
          margin-bottom: 0px !important;
          color: #ffffff;
          margin-top: -45px;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 149.436px;

            line-height: 209px;
          }
          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 90.436px;
            line-height: 148px;
          }
          @media screen and (max-width: 992px) and(min-width: 376px) {
            font-size: 64.5137px;
            line-height: 136px;
          }
          @media screen and (min-width: 320px) and (max-width: 375px) {
            font-size: 52.1222px;
            line-height: 122px;
          }
        }
        .poweredby {
          font-style: normal;
          font-size: 32.5344px;
          line-height: 27px;
          // display: flex;
          // align-items: flex-end;
          text-transform: capitalize;
          margin-top: -6px;
          font-weight: 800;
          color: #ffffff;
          @media screen and (min-width: 1025px) and (max-width: 1999px) {
            font-size: 19.5344px;
          }

          @media screen and (max-width: 1024px) and( min-width: 376px) {
            font-size: 11.2959px;
            line-height: 14px;
          }

          @media screen and(max-width:500px) {
            margin-top: -25px;
          }
          @media screen and (min-width: 320px) and (max-width: 375px) {
            margin-top: -29px;
            font-size: 11.2959px;
            line-height: 14px;
          }

          .square {
            border: 1px solid white;
            width: 1rem;
            background-color: white;
            height: 1rem;
          }
        }
      }
    }
  }
  .moverse-main {
    text-align: center;
    margin-top: 2rem;
    color: $white;
    //position: absolute;
    // background-image: url("../../images/moverse-main/bg-main.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    //position: relative;
    //border: 4px solid red;

    h3 {
      color: $seagreen;
    }
    .logo {
      width: 34rem;
    }
    h4 {
      img {
        width: 10rem;
      }
    }
    .shoe {
      position: relative;
      // margin-bottom: 2rem;
    }
    .small-shoe {
      position: absolute;
      right: 27%;
      top: 25%;
    }
    .lifestyle {
      position: absolute;
      top: 77%;
      width: 22%;
      margin-left: 7rem;
      p {
        font-size: 16px;
        text-align: start;
      }
    }
    .follow-us {
      position: absolute;
      top: 77%;
      right: 7%;
      //display: flex;
      // flex-direction: column;
      p {
        font-size: 16px;
        text-align: start;
      }
      img {
        margin-right: 1rem;
        margin-left: 1rem;
      }
    }
  }
  //moverse main//

  //play to earn slide
  .play-to-earn {
    //position: relative;
    @media screen and (max-width: 991px) {
      //margin-top: 4rem;
    }

    .banner {
      @media screen and (min-width: 768px) {
        aspect-ratio: 1080/1920;
      }
    }

    .slider-images {
      .image-sec {
        position: relative;
      }
      .text-sec {
        top: 20%;
        left: 8%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;
        text-align: left;
        width: 30%;
        @media screen and (min-width: 1025px) and (max-width: 1999px) {
          top: 13%;
        }
        @media screen and (max-width: 1024px) and (min-width: 769px) {
          top: 8%;
          width: 38%;
        }
        @media screen and (max-width: 768px) and (min-width: 376px) {
          top: 8%;
          width: 58%;
        }
        @media screen and (min-width: 320px) and (max-width: 375px) {
          width: 60%;
          top: 8%;
        }
        .multiplayer {
          font-style: normal;
          font-weight: 500;
          font-size: 33.7809px;
          line-height: 41px;
          letter-spacing: 0.765em;
          text-transform: uppercase;
          margin-bottom: 0px;
          color: #ffffff;
          @media screen and (max-width: 1024px) {
            font-size: 16.9788px;
            letter-spacing: 0.765em;
            line-height: 21px;
          }
        }
        .playto {
          font-style: normal;
          font-weight: 800;
          font-size: 128.303px;
          line-height: 156px;
          text-transform: uppercase;
          margin-bottom: 0px !important;
          color: #ffffff;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 88.303px;
            line-height: 114px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 66.303px;
            line-height: 99px;
          }
          @media screen and (max-width: 992px) {
            font-size: 44.6796px;
            line-height: 54px;
          }
        }
        .earn {
          font-style: normal;
          font-weight: 800;
          font-size: 198.942px;
          line-height: 243px;
          margin-bottom: 0px !important;
          color: #ffea2d;
          margin-top: -45px;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 144.942px;
            line-height: 130px;
            margin-top: 0px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 106.942px;
            line-height: 130px;
          }
          @media screen and (max-width: 992px) {
            font-size: 69.2785px;
            line-height: 128px;
          }
        }
        .detail {
          font-style: normal;
          font-weight: 500;
          font-size: 23.5856px;
          line-height: 29px;
          text-transform: capitalize;
          margin-top: -6px;
          color: #ffffff;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 16.5856px;
            line-height: 24px;
            margin-top: 8px;
          }
          @media screen and (min-width: 993px) and (max-width: 1439px) {
            margin-top: -10px;
            font-size: 16.5856px;
            line-height: 24px;
          }
          @media screen and (max-width: 992px) {
            font-size: 8.21333px;
            line-height: 11px;
            margin-top: -28px;
          }
        }
      }
    }
  }

  //play to earn slide

  //stayfit slide
  .stay-fit-earn {
    //position: relative;
    @media screen and (max-width: 991px) {
      //margin-top: 4rem;
    }

    .banner {
      @media screen and (min-width: 768px) {
        aspect-ratio: 1080/1920;
      }
    }

    .slider-images {
      .image-sec {
        position: relative;
      }
      .text-sec {
        top: 15%;
        right: 8%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;
        text-align: right;
        width: 30%;

        @media screen and (max-width: 1024px) and(min-width:769px) {
          top: 8%;
          width: 38%;
        }
        @media screen and (min-width: 376px) and (max-width: 768px) {
          width: 58%;
          top: 8%;
        }

        @media screen and (min-width: 320px) and (max-width: 375px) {
          width: 60%;
          top: 5%;
        }

        .playto {
          font-style: normal;
          font-weight: 800;
          font-size: 128.303px;
          line-height: 156px;
          text-transform: uppercase;
          margin-bottom: 0px !important;
          color: #ffffff;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 88.303px;
            line-height: 114px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 65.303px;
            line-height: 73px;
          }
          @media screen and (max-width: 992px) {
            font-size: 44.6796px;
            line-height: 54px;
          }
        }
        .earn {
          font-style: normal;
          font-weight: 800;
          font-size: 198.942px;
          line-height: 243px;
          margin-bottom: 0px !important;
          color: #12e4f6;
          margin-top: -45px;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 144.942px;
            line-height: 130px;
            margin-top: 0px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 117.942px;
            line-height: 190px;
          }
          @media screen and (max-width: 992px) {
            font-size: 69.2785px;
            line-height: 128px;
          }
        }
        .detail {
          font-style: normal;
          font-weight: 500;
          font-size: 23.5856px;
          line-height: 29px;
          text-transform: capitalize;
          margin-top: -6px;
          color: #ffffff;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 16.5856px;
            line-height: 24px;
            margin-top: 8px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            margin-top: -34px;
            font-size: 16.5856px;
            line-height: 24px;
          }

          @media screen and (max-width: 992px) {
            font-size: 8.21333px;
            line-height: 11px;
            margin-top: -28px;
          }
        }
      }
    }
  }

  //stay fit slide

  //nft sneaker slide
  .nft-main {
    //position: relative;
    @media screen and (max-width: 991px) {
      //margin-top: 4rem;
    }

    .banner {
      @media screen and (min-width: 768px) {
        aspect-ratio: 1080/1920;
      }
    }

    .slider-images {
      .image-sec {
        position: relative;
      }
      .text-sec {
        top: 5%;
        left: 8%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;
        //text-align: center;
        width: 50%;
        @media screen and (max-width: 1024px) {
          top: 3%;
          width: 90%;
        }

        .unique {
          font-style: normal;
          font-weight: 500;
          font-size: 33.7809px;
          line-height: 41px;
          letter-spacing: 0.765em;
          text-transform: uppercase;
          margin-bottom: 0px;
          color: #ffffff;
          @media screen and (max-width: 1024px) {
            font-size: 16.9788px;
            letter-spacing: 0.765em;
            line-height: 21px;
          }
        }
        .nft {
          font-style: normal;
          font-weight: 800;
          font-size: 138.249px;
          line-height: 169px;
          /* identical to box height */

          text-transform: uppercase;

          color: #ffffff;
          margin-bottom: 0px !important;

          //margin-top: -45px;

          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 87.249px;
            line-height: 95px;
          }
          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 57.249px;
            line-height: 43px;
          }
          @media screen and (max-width: 992px) {
            font-size: 37.68px;
            line-height: 42px;
          }
        }
        .detail {
          font-style: normal;
          font-weight: 500;
          font-size: 23.5856px;
          line-height: 29px;
          text-transform: capitalize;
          //margin-top: -6px;
          color: #ffffff;
          @media screen and (min-width: 993px) and (max-width: 1999px) {
            font-size: 16.5856px;
          }
          @media screen and (max-width: 992px) {
            font-size: 8.21px;
            line-height: 10px;
          }
        }
        .button-mp {
          border: 1.46515px solid #00ffff;
          border-radius: 35.5299px;
          color: white;
          padding: 1rem;
          margin-top: 2rem;
          @media screen and (max-width: 1024px) {
            padding: 0.5rem;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  //nft sneakerslide

  //compete in sports
  .competeinsports {
    //position: relative;
    @media screen and (max-width: 991px) {
      //margin-top: 4rem;
    }

    .banner {
      @media screen and (min-width: 768px) {
        aspect-ratio: 1080/1920;
      }
    }

    .slider-images {
      .image-sec {
        position: relative;
      }
      .text-sec {
        top: 20%;
        left: 8%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;
        text-align: left;
        width: 37%;
        //border: 1px solid red;
        @media screen and (min-width: 1025px) and (max-width: 1999px) {
          width: 40%;
        }
        @media screen and (max-width: 1024px) {
          width: 42%;
          top: 3%;
          left: 7%;
        }
        @media screen and (max-width: 475px) {
          width: 85%;
          top: 3%;
          left: 7%;
        }

        .compete {
          font-style: normal;
          font-weight: 800;
          font-size: 110.303px;
          line-height: 156px;
          text-transform: uppercase;
          margin-bottom: 0px;
          color: #ffffff;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 76.303px;
            line-height: 114px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 57.303px;
            line-height: 82px;
          }
          @media screen and (max-width: 992px) {
            font-size: 44.6796px;
            line-height: 54px;
          }
        }
        .sport {
          font-style: normal;
          font-weight: 800;
          font-size: 198.942px;
          line-height: 243px;
          /* identical to box height */

          text-transform: uppercase;
          margin-bottom: 0px !important;
          color: #12e4f6;
          margin-top: -45px;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 135.942px;
            line-height: 85px;
            margin-top: 0px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 95.942px;
            line-height: 139px;
          }
          @media screen and (max-width: 992px) {
            font-size: 66.2785px;
            line-height: 129px;
          }
        }
        .detail {
          font-style: normal;
          font-weight: 500;
          font-size: 23.5856px;
          line-height: 29px;
          text-transform: capitalize;
          margin-top: -6px;
          color: #ffffff;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            margin-top: 12px;
            font-size: 16.5856px;
          }
          @media screen and (min-width: 993px) and (max-width: 1439px) {
            margin-top: -17px;
            font-size: 16.5856px;
            //width: 50%;
          }
          @media screen and (max-width: 992px) {
            font-size: 8.21333px;
            line-height: 10px;
            margin-top: -31px;
          }
        }
        .button-mp {
          border: 1.46515px solid #00ffff;
          border-radius: 35.5299px;
          color: white;
          padding: 1rem 1.5rem;
          margin-top: 2rem;

          @media screen and (max-width: 1024px) {
            padding: 0.4rem 1.5rem;
            margin-top: 0rem;
          }
        }
      }
    }
  }

  //compete in sports

  //engage to earn new
  .engagetoearn {
    //position: relative;
    @media screen and (max-width: 991px) {
      //margin-top: 4rem;
    }

    .banner {
      @media screen and (min-width: 768px) {
        aspect-ratio: 1080/1920;
      }
    }

    .slider-images {
      .image-sec {
        position: relative;
      }
      .text-sec {
        top: 15%;
        right: 8%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;
        text-align: right;
        width: 35%;

        @media screen and (min-width: 1025px) and (max-width: 1999px) {
          width: 40%;
        }

        @media screen and (max-width: 1024px) and( min-width:769px) {
          right: 7%;

          width: 42%;
          top: 5%;
        }
        @media screen and (max-width: 768px) {
          right: 7%;

          width: 66%;
          top: 8%;
        }

        .playto {
          font-style: normal;
          font-weight: 800;
          font-size: 128.303px;
          line-height: 156px;
          text-transform: uppercase;
          margin-bottom: 0px !important;
          color: #ffffff;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 88.303px;
            line-height: 114px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 65.303px;
          }
          @media screen and (max-width: 992px) {
            font-size: 38.6796px;
            line-height: 54px;
          }
        }
        .earn {
          font-style: normal;
          font-weight: 800;
          font-size: 198.942px;
          line-height: 243px;
          margin-bottom: 0px !important;
          color: #ffea2d;
          margin-top: -45px;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 144.942px;
            line-height: 85px;
            margin-top: 0px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 126.942px;
            line-height: 85px;
          }
          @media screen and (max-width: 992px) {
            font-size: 61.2785px;
            line-height: 84px;
            margin-top: -22px;
          }
        }
        .detail {
          font-style: normal;
          font-weight: 500;
          font-size: 23.5856px;
          line-height: 29px;
          text-transform: capitalize;
          margin-top: -6px;
          color: #ffffff;
          @media screen and (min-width: 601px) and (max-width: 1999px) {
            font-size: 16.5856px;
            line-height: 24px;
            margin-top: 20px;
          }
          @media screen and (max-width: 600px) {
            font-size: 8.21333px;
            line-height: 10px;
          }
        }
      }
    }
  }

  //engage to earn new

  //healthverse
  .healthverse {
    //position: relative;
    @media screen and (max-width: 991px) {
      //margin-top: 4rem;
    }

    .banner {
      @media screen and (min-width: 768px) {
        aspect-ratio: 1080/1920;
      }
    }

    .slider-images {
      .image-sec {
        position: relative;
      }
      .text-sec {
        top: 10%;
        left: 0%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;
        //text-align: center;
        width: 100%;
        text-align: center;

        @media screen and (max-width: 1024px) and(min-width:376px) {
          top: 4%;
          left: 7%;
          width: 80%;
        }
        @media screen and (min-width: 320px) and (max-width: 375px) {
          width: 100%;
          top: 6%;
        }

        .unique {
          font-style: normal;
          font-weight: 500;
          font-size: 31.7809px;
          line-height: 40px;
          letter-spacing: 0.765em;
          text-transform: uppercase;
          //margin-bottom: 0px;
          color: #ffffff;
          @media screen and (max-width: 1024px) {
            font-size: 16.9788px;
            line-height: 13px;
          }
        }
        .health {
          font-style: normal;
          font-weight: 800;
          font-size: 138.249px;
          line-height: 115px;
          /* identical to box height */

          text-transform: uppercase;

          color: #ffffff;
          margin-bottom: 0px !important;

          //margin-top: -45px;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 87.249px;
            line-height: 83px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 71.249px;
            line-height: 71px;
          }
          @media screen and (max-width: 992px) {
            font-size: 43px;
            line-height: 54px;
          }
          span {
            color: #f20b50;
          }
        }
        .detail {
          font-style: normal;
          font-weight: 500;
          font-size: 23.5856px;
          line-height: 38px;
          text-transform: capitalize;
          //margin-top: -6px;
          //margin: 0;
          color: #ffffff;
          width: 70%;
          margin: auto;

          @media screen and (min-width: 601px) and (max-width: 1439px) {
            font-size: 16px;
            line-height: 23px;
          }

          @media screen and (max-width: 600px) {
            font-size: 8.21px;
            line-height: 10px;
          }
        }
        .button-mp {
          border: 1.46515px solid #00ffff;
          border-radius: 35.5299px;
          color: white;
          padding: 1rem;
          margin-top: 2rem;
        }
      }
    }
  }

  //healthverse

  //scholorship
  .scholorship {
    //position: relative;
    @media screen and (max-width: 991px) {
      //margin-top: 4rem;
    }

    .banner {
      @media screen and (min-width: 768px) {
        aspect-ratio: 1080/1920;
      }
    }

    .slider-images {
      .image-sec {
        position: relative;
      }
      .text-sec {
        top: 20%;
        left: 8%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;
        //text-align: center;
        width: 50%;
        text-align: left;

        @media screen and (max-width: 1024px) and(min-width:376px) {
          top: 4%;
          left: 7%;
          width: 56%;
        }
        @media screen and (min-width: 320px) and (max-width: 375px) {
          width: 60%;
          top: 5%;
        }

        .unique {
          font-style: normal;
          font-weight: 500;
          font-size: 31.7809px;
          line-height: 40px;
          letter-spacing: 0.765em;
          text-transform: uppercase;
          //margin-bottom: 0px;
          color: #ffffff;
          @media screen and (max-width: 1024px) {
            font-size: 16.9788px;
            line-height: 13px;
          }
        }
        .nft {
          font-style: normal;
          font-weight: 800;
          font-size: 138.249px;
          line-height: 115px;
          /* identical to box height */

          text-transform: uppercase;

          color: #ffffff;
          margin-bottom: 0px !important;

          //margin-top: -45px;
          @media screen and (min-width: 1440px) and (max-width: 1999px) {
            font-size: 87.249px;
            line-height: 83px;
          }

          @media screen and (min-width: 993px) and (max-width: 1439px) {
            font-size: 71.249px;
            line-height: 71px;
          }
          @media screen and (max-width: 992px) {
            font-size: 43px;
            line-height: 54px;
          }
        }
        .detail {
          font-style: normal;
          font-weight: 500;
          font-size: 23.5856px;
          line-height: 38px;
          text-transform: capitalize;
          //margin-top: -6px;
          margin: 0;
          color: #ffffff;

          @media screen and (min-width: 601px) and (max-width: 1439px) {
            font-size: 16px;
            line-height: 23px;
          }

          @media screen and (max-width: 600px) {
            font-size: 8.21px;
            line-height: 10px;
          }
        }
        .button-mp {
          border: 1.46515px solid #00ffff;
          border-radius: 35.5299px;
          color: white;
          padding: 1rem;
          margin-top: 2rem;
        }
      }
    }
  }

  //scholorship

  //new partners
  .partner {
    //position: relative;
    @media screen and (max-width: 991px) {
      //margin-top: 4rem;
    }

    .banner {
      @media screen and (min-width: 768px) {
        aspect-ratio: 1080/1920;
      }
    }

    .slider-images {
      .image-sec {
        position: relative;
      }
      .text-sec {
        top: 20%;
        left: 8%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;
        //text-align: center;
        width: 40%;
        @media screen and(min-width:1025px) and(max-width: 1999px) {
          width: 50%;
        }
        @media screen and (max-width: 1024px) and(min-width: 769px) {
          top: 8%;
          left: 7%;
          width: 57%;
        }

        @media screen and(max-width: 768px) {
          top: 9%;
          left: 8%;
          z-index: 1;
          position: absolute;
          border: 1px solid transparent;
          width: 87%;
        }

        .trust {
          font-style: normal;
          font-weight: 500;
          font-size: 33.7809px;
          line-height: 41px;
          letter-spacing: 0.765em;
          text-transform: uppercase;
          //margin-bottom: 0px;
          color: #ff9de4;

          @media screen and (max-width: 1024px) {
            font-size: 14.9788px;
            line-height: 13px;
            letter-spacing: 0.765em;
          }
        }
        .part {
          font-style: normal;
          font-weight: 800;
          font-size: 125.249px;
          line-height: 115px;
          /* identical to box height */

          text-transform: uppercase;

          color: #ffffff;
          margin-bottom: 0px !important;

          //margin-top: -45px;
          @media screen and(min-width:1440px) and(max-width: 1999px) {
            font-size: 87.249px;
            line-height: 75px;
          }

          @media screen and(min-width: 993px) and(max-width: 1439px) {
            font-size: 76.249px;
            line-height: 73px;
          }
          @media screen and (max-width: 992px) {
            font-size: 44.68px;
            line-height: 54px;
          }
        }
        .detail {
          font-style: normal;
          font-weight: 500;
          font-size: 23.5856px;
          line-height: 29px;
          text-transform: capitalize;
          //margin-top: -6px;
          color: #ffffff;
          @media screen and (max-width: 600px) {
            font-size: 8.21px;
            line-height: 10px;
          }
        }
        .button-mp {
          border: 1.46515px solid #00ffff;
          border-radius: 35.5299px;
          color: white;
          padding: 1rem;
          margin-top: 2rem;
        }
      }
      .slider {
        bottom: 20%;
        //left: 8%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;
        //text-align: center;
        width: 100%;
        margin: 0;
        padding: 0;
        outline: none !important;
        img {
          outline: none;
        }
        img:active {
          outline: none;
        }
        @media screen and(min-width:1440px) and(max-width: 1999px) {
          bottom: 10%;
        }
        @media screen and(min-width:992px) and(max-width: 1439px) {
          bottom: 0%;
        }
        @media screen and(min-width:769px) and(max-width: 1024px) {
          bottom: 0%;
        }
      }
      .mobile-slide {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        top: 43%;
        //left: 8%;
        z-index: 1;
        position: absolute;
        border: 1px solid transparent;

        .img-part {
          width: 10rem;
          //border: 2px solid red;
          margin: 0 0.5rem;
        }
      }
    }
  }

  //newpartners

  //what we do//
  .whatwedo {
    text-align: center;
    color: $white;
    margin: 4rem 5rem;
    @media screen and (max-width: 767px) {
      margin: 2rem 2rem;
    }
    //border: 1px solid red;
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .sections {
      display: flex;
      margin-top: 3rem;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .launch {
      border: 2.88985px solid $seagreen;
      padding: 2rem 3rem;
      width: 90%;
      border-radius: 28.8985px;
      text-align: start;
      display: flex;
      @media screen and (min-width: 2000px) {
        width: auto;
      }
      @media screen and (max-width: 1450px) and (min-width: 769px) {
        width: 100%;
        padding: 1rem;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0rem 1rem 1rem 1rem;
      }
      .head {
        color: $seagreen;
      }
      .launch-img {
        width: 40%;
        @media screen and (min-width: 2000px) {
          width: auto;
        }
      }
      h5 {
        font-weight: 400;
        font-size: 37.1395px;
        text-align: center;
        font-weight: bold;
        @media screen and (max-width: 767px) {
          font-size: 25px;
        }
      }
      .options {
        padding: 0rem 2rem;
        .section1 {
          display: flex;
          flex-direction: column;
          text-align: start;
          margin-top: 2rem;
          p {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }
    .teleport-shoe {
      width: 55%;
      margin-left: 5rem;
      @media screen and (min-width: 1024px) and (max-width: 1500px) {
        display: flex;
      }
      @media screen and (max-width: 1024px) {
        width: 80%;
        margin-top: 4rem;
        margin-left: 0rem;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  //what we do//

  //car game
  .car-game {
    text-align: center;
    color: $white;
    background-image: url("../../images/whatwedo/neonbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    //position: relative;
    //margin: 0rem 2rem;
    @media screen and (min-width: 1600px) {
      //position: relative;
    }
    @media screen and (max-width: 1500px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      margin: 0rem;
    }
    .img-hurrah {
      width: 28rem;
      // position: absolute;
      // right: 21%;
      // z-index: 1001;
      // top: 135%;

      // @media screen and (min-width: 1888px) {
      //   position: absolute;
      //   right: 21%;
      //   z-index: 1001;
      //   top: 116%;
      // }

      // @media screen and (min-width: 1830px)and (max-width:1887) {
      //   position: absolute;
      //   right: 21%;
      //   z-index: 1001;
      //   top: 108%;
      // }
      // @media screen and (min-width: 1800px)and (max-width: 1829px) {
      //   position: absolute;
      //   right: 21%;
      //   z-index: 1001;
      //   top: 129%;
      // }

      // @media screen and (max-width: 1440px) and (min-width: 1025px) {
      //   right: 12%;
      //   z-index: 1001;
      //   top: 89%;
      // }
      // @media screen and (max-width: 1024px) {
      //   right: 23%;
      //   z-index: 1001;
      //   top: 190%;
      // }
    }
    .hurah-rep {
      margin-top: 10rem;
      // margin-bottom: 10rem;
      @media screen and (max-width: 768px) {
        margin-top: 5rem;
      }
    }
    .hurray {
      // position: absolute;
      // top: 140%;
      // z-index: 1001;
      background-color: #1f2f3c;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
      color: #a72828;
      // right: 33%;
      animation: zoom-in-zoom-out 2s ease-out infinite;
      font-size: 1.5rem;
      //font-weight: 600;
      padding: 2rem;
      @media screen and (min-width: 1500px) {
        top: 126%;
      }
    }

    .bg-slider {
      //background-image: url("../../images/whatwedo/neonbg.png");
      //background-repeat: no-repeat;
      //background-size: cover;
      //aspect-ratio: 1017/943;
      @media screen and (max-width: 1440px) {
        width: 100%;
      }
      .lucky-shoe {
        margin-top: 4.5rem;
        h2 {
          color: #5edfff;
        }
      }
      .slider {
      }
    }
    .tap-stop {
      margin-bottom: 4rem;
      color: #5edfff;
      //margin-top: 12rem;
      //position: absolute;
      /* top: 166%; */
      // top: 73%;
      // right: 44%;
      // margin-top: -300px;
      // margin-bottom: 136px;

      // position: absolute;
      // top: 74%;
      // right: 34%;
      // z-index: 1000;
    }
    .animate-fall {
      z-index: 1001 !important;
      position: absolute;
      pointer-events: none;
      //width: 100%;
      inset: 271rem -2rem 85rem -2rem !important;
      @media screen and (max-width: 1440px) and (min-width: 1025px) {
        inset: 250rem -2rem !important;
      }

      @media screen and (max-width: 1024px) and (min-width: 769px) {
        inset: 263rem -2rem !important;
      }
      @media screen and (max-width: 768px) and(min-width: 426px) {
        inset: 379rem -2rem !important;
      }
      @media screen and (max-width: 425px) {
        inset: 332rem -2rem !important;
      }
    }
    .v-btn {
      position: relative;
      width: 100%;
      z-index: 1000;

      .video1 {
        width: 100%;
        // position: relative;
        // z-index: 1000;
      }
      .start-btn {
        position: absolute;
        top: 47%;
        z-index: 1001;
        right: 41%;
        .btn-s {
          background: linear-gradient(126.21deg, #6a35ee 0%, #9930ef 14.24%, #5737ee 49.56%, #795ceb 93.2%);
          border-radius: 10px;
          border: 1px solid #795ceb;
          font-size: 2rem;
          width: 100%;
          padding: 0.4rem 5.65rem;
          font-weight: 600;
          color: white;
          animation: zoom-in-zoom-out 2s ease-out infinite;
          //       -webkit-animation: glowing 1500ms infinite;
          // -moz-animation: glowing 1500ms infinite;
          // -o-animation: glowing 1500ms infinite;
          // animation: glowing 1500ms infinite;

          @media screen and (max-width: 1440px) {
            padding: 0.4rem 2.65rem;
          }

          @media screen and (max-width: 767px) {
            padding: 0.2rem 1.65rem;
            font-size: 1rem;
          }
        }
        @keyframes zoom-in-zoom-out {
          0% {
            transform: scale(1, 1);
            background-color: #6a35ee;
            -webkit-box-shadow: 0 0 3px #6a35ee;
          }
          50% {
            transform: scale(1.2, 1.2);
            background-color: #9930ef;
            -webkit-box-shadow: 0 0 40px #9930ef;
          }
          100% {
            transform: scale(1, 1);
            background-color: #6a35ee;
            -webkit-box-shadow: 0 0 3px #6a35ee;
          }
        }
      }
    }

    .slider {
      //width: 30%;
      margin-left: auto;
      margin-right: auto;
      z-index: 1000;
    }
    .particle {
      position: absolute;
      top: 124%;
      z-index: 2;
      left: 38%;
    }
    .car-box {
      border: 3px solid transparent;
      padding: 20px;

      img {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        //margin-top: 5rem;
        //transform: rotate(335deg);
        // animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        // transform: translate3d(0, 0, 0);
        // backface-visibility: hidden;
        // perspective: 1000px;
      }
      // @keyframes shake {
      //   10%, 90% {
      //     transform: translate3d(-1px, 0, 0);
      //   }

      //   20%, 80% {
      //     transform: translate3d(2px, 0, 0);
      //   }

      //   30%, 50%, 70% {
      //     transform: translate3d(-4px, 0, 0);
      //   }

      //   40%, 60% {
      //     transform: translate3d(4px, 0, 0);
      //   }}
    }
  }
  //car game

  //new cateogry

  .new-category {
    //padding: 2rem 5rem;
    .text-sec-category {
      width: 70%;
      margin-left: 8%;
      margin-top: 6%;
      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 78.2727px;
        line-height: 95px;
        text-transform: uppercase;

        color: #ffffff;

        @media screen and (max-width: 1024px) and( min-width: 376px) {
          font-size: 40px;
          line-height: 29px;
        }
        @media screen and (max-width: 375px) {
          margin-top: 10%;
          font-size: 40px;
          line-height: 29px;
        }
      }
      .detail {
        font-style: normal;
        font-weight: 500;
        font-size: 23.5856px;
        line-height: 29px;
        text-transform: capitalize;

        color: #ffffff;

        @media screen and (min-width: 601px) and (max-width: 1439px) {
          font-size: 16px;
          line-height: 23px;
        }

        @media screen and (max-width: 600px) {
          font-size: 8.21px;
          line-height: 10px;
        }
      }
    }
    .image-sec {
      padding: 0 15rem;
      @media screen and (max-width: 1999px) {
        padding: 0;
      }
    }
  }

  //new category

  //new servicebox

  .new-servicebox {
    //padding: 2rem 5rem;
    margin-top: 4rem;
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
    .text-sec-category {
      width: 70%;
      margin: 0 auto;
      text-align: center;
      //margin-left: 8%;
      @media screen and (max-width: 1024px) {
        width: 90%;
      }

      .intro {
        font-weight: 500;
        font-size: 33.7809px;
        line-height: 41px;
        letter-spacing: 0.765em;
        text-transform: uppercase;
        margin-bottom: 0px;
        color: #ffffff;

        @media screen and (max-width: 1024px) {
          font-size: 15.9788px;
          line-height: 13px;
        }
      }
      .servicebo {
        font-style: normal;
        font-weight: 800;
        font-size: 138.249px;
        line-height: 169px;
        /* identical to box height */

        text-transform: uppercase;

        color: #ffffff;
        @media screen and (min-width: 1021px) and (max-width: 1999px) {
          font-size: 100.249px;
          line-height: 82px;
        }

        @media screen and (max-width: 1024px) {
          font-size: 44.68px;
          line-height: 54px;
        }
      }
      .detail {
        font-style: normal;
        font-weight: 500;
        font-size: 23.5856px;
        line-height: 29px;
        text-transform: capitalize;

        color: #ffffff;
        @media screen and (max-width: 1024px) {
          font-size: 8.21px;
          line-height: 10px;
        }
      }
    }
  }

  //new service box

  //token

  .token {
    //padding: 2rem 5rem;
    .text-sec-category {
      width: 70%;
      margin-left: 8%;
      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 78.2727px;
        line-height: 95px;
        text-transform: uppercase;

        color: #ffffff;

        @media screen and (max-width: 1024px) {
          font-size: 24px;
          line-height: 29px;
        }
      }
      .detail {
        font-style: normal;
        font-weight: 500;
        font-size: 23.5856px;
        line-height: 29px;
        text-transform: capitalize;

        color: #ffffff;

        @media screen and (max-width: 1024px) {
          font-size: 8.21px;
          line-height: 10px;
        }
      }
    }
    .image-sec {
      //padding: 0 15rem;
      @media screen and (max-width: 1999px) {
        padding: 0;
      }
    }
  }

  //token

  //game play//
  .gameplay1 {
    text-align: center;
    color: $white;
    padding: 4rem 5rem;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 769px) {
      background-image: url("../../images/gameplay/gamecategory.png");
      aspect-ratio: 3840/1742;
    }
    @media screen and (max-width: 768px) {
      background-image: url("../../images/gameplay/category-mob.png");
      height: 45rem;
      padding: 2rem;
    }
  }
  .gameplay-turkish {
    text-align: center;
    color: $white;
    padding: 4rem 5rem;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 769px) {
      background-image: url("../../images/gameplay/turkishwebbg.png");
      aspect-ratio: 3840/1742;
    }
    @media screen and (max-width: 768px) {
      background-image: url("../../images/gameplay/turkishmobbg.png");
      height: 45rem;
      padding: 2rem;
    }
  }
  .gameplay-vit {
    text-align: center;
    color: $white;
    padding: 4rem 5rem;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 769px) {
      background-image: url("../../images/gameplay/webvit.png");
      aspect-ratio: 3840/1742;
    }
    @media screen and (max-width: 768px) {
      background-image: url("../../images/gameplay/mobvit.png");
      height: 45rem;
      padding: 2rem;
    }
  }
  .gameplay-chn {
    text-align: center;
    color: $white;
    padding: 4rem 5rem;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 769px) {
      background-image: url("../../images/gameplay/webchn.png");
      aspect-ratio: 3840/1742;
    }
    @media screen and (max-width: 768px) {
      background-image: url("../../images/gameplay/mobchn.png");
      height: 45rem;
      padding: 2rem;
    }
  }
  .gameplay-es {
    text-align: center;
    color: $white;
    padding: 4rem 5rem;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 769px) {
      background-image: url("../../images/gameplay/webspanish.png");
      aspect-ratio: 3840/1742;
    }
    @media screen and (max-width: 768px) {
      background-image: url("../../images/gameplay/mobspanish.png");
      height: 45rem;
      padding: 2rem;
    }
  }
  .gameplay {
    text-align: center;
    color: $white;
    margin: 4rem 5rem;
    .textp {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      margin: 2rem 3rem;
    }
    .categories {
      //border: 2.88985px solid $seagreen;
      padding: 2rem 3rem;
      border-radius: 12px;
      text-align: start;
      @media screen and (min-width: 993px) and (max-width: 1500px) {
        padding: 2rem 0rem;
      }
      @media screen and (max-width: 767px) {
        padding: 0px;
      }
      .detail {
        width: 40%;
      }
      .cards-section {
        margin-bottom: 2rem;
        .card-deck {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          @media screen and (min-width: 993px) and (max-width: 1500px) {
            width: 100%;
          }
          @media screen and (max-width: 992px) {
            display: flex;
            flex-direction: column;
            //width: 75%;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }

          .card {
            padding: 2rem 0rem 0rem;
            border-radius: 17.2375px;
            margin-left: 3rem;
            margin-right: 3rem;
            @media screen and (min-width: 993px) and (max-width: 1500px) {
              margin-left: 1rem;
              margin-right: 1rem;
            }
            @media screen and (max-width: 992px) {
              margin-top: 1.5rem;
              margin-bottom: 1.5rem;
            }
            @media screen and (max-width: 767px) {
              margin-left: 0rem;
              margin-right: 0rem;
              margin-bottom: 30px;
            }
          }
          .card-img-top {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 70%;
          }
          .card-body {
          }
          .common {
            background-color: $teal;
            border: 1px solid $seagreen2;
            .hr-common {
              border-top: 5px solid $seagreen2;
              // color: red;
              margin-bottom: 0px;
              width: 50%;
              margin-left: auto;
              margin-right: auto;
            }
          }
          .booster {
            background-color: $bottlegreen;
            border: 1px solid $green2;
            .hr-common {
              border-top: 5px solid $green2;
              // color: red;
              margin-bottom: 0px;
              width: 50%;
              margin-left: auto;
              margin-right: auto;
            }
          }
          .rare {
            background-color: $maroon;
            border: 1px solid $maroon2;
            .hr-common {
              border-top: 5px solid $maroon2;
              // color: red;
              margin-bottom: 0px;
              width: 50%;
              margin-left: auto;
              margin-right: auto;
            }
          }
          .supreme {
            background-color: $brown;
            border: 1px solid $brown2;
            .hr-common {
              border-top: 5px solid $brown2;
              // color: red;
              margin-bottom: 0px;
              width: 50%;
              margin-left: auto;
              margin-right: auto;
            }
          }
          .p {
            width: 100%;
          }
        }
      }
      .process {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .card {
          margin-right: 3rem;
          margin-top: 3rem;
          border-radius: 17.24px;
          max-width: "780px";
          width: 45%;
          background-color: transparent;
          border: 1px solid white;
          margin-right: 20px;
          @media screen and (min-width: 1860px) {
            width: 45%;
            margin-left: auto;
            margin-right: auto;
          }
          @media screen and (max-width: 1024px) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          }
          .verticalLine {
            border-left: 0.5px solid $white;
            opacity: 0.6;
            margin: 15px 0px;
            // margin-left: 4rem;
            // margin-right: 4rem;
            //border: 1px solid red;
          }
          .card-text-num {
            text-align: end;
            // font-size: 20px;
            font-family: $Bellota;
            font-weight: bold;
          }
          .card-title {
            @media screen and (max-width: 767px) {
              text-align: center;
            }
          }
        }
        .img-style {
          display: flex;
          justify-content: center;
          align-items: center;
          .card-img {
            height: 150px;
            @media screen and (max-width: 767px) {
              padding: 10px;
            }
          }
        }
      }
    }
  }
  //game play//
  //smartphone app//
  .smartphone {
    // background-image: url("../../images/smartphone/bg-smartphone.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    text-align: center;
    //margin-top: 2rem;
    padding: 4rem 5rem;
    color: $white;
    border: 1px solid transparent;
    @media screen and (max-width: 767px) {
      margin-top: 0rem;
      padding: 2rem;
    }
    .smart-content {
      //margin: 4rem 5rem;
      // @media screen and (max-width: 767px) {
      //   margin: 2rem 2rem;
      // }
      .text-sec-category {
        width: 70%;
        /* margin-left: 8%; */
        margin: auto;
        @media screen and (max-width: 1024px) {
          width: 90%;
        }
        .intro {
          font-size: 33.7809px;
          line-height: 41px;
          letter-spacing: 0.765em;
          color: #fff09d;
          @media screen and (max-width: 1024px) {
            font-size: 15.9788px;
            line-height: 7px;
          }
        }
        .smartphone1 {
          font-style: normal;
          font-weight: 700;
          font-size: 138.249px;
          line-height: 169px;
          text-transform: uppercase;

          color: #ffffff;
          @media screen and (min-width: 1025px) and (max-width: 1999px) {
            font-size: 100.249px;
            line-height: 82px;
          }

          @media screen and (max-width: 1024px) {
            font-size: 35.68px;
            line-height: 54px;
          }
          // @media screen and (max-width: 1020px) {
          //   font-size: 24px;
          //   line-height: 29px;
          // }
        }
        .detail {
          font-style: normal;
          font-weight: 500;
          font-size: 23.5856px;
          line-height: 29px;
          text-transform: capitalize;

          color: #ffffff;
          @media screen and (min-width: 601px) and (max-width: 1439px) {
            font-size: 16px;
            line-height: 23px;
          }

          @media screen and (max-width: 600px) {
            font-size: 8.21px;
            line-height: 10px;
          }
        }
      }
      .active-tab-class {
        border: 5px solid #8f00ff;
        border-radius: 46.1384px;
      }
      .smart-img {
        width: 20rem;
        @media screen and (max-width: 768px) {
          width: 14rem;
        }
      }
      .smart-img2 {
        width: 25rem;
        @media screen and (max-width: 1600px) and (min-width: 769px) {
          width: 20rem;
        }
        @media screen and (max-width: 768px) {
          width: 14rem;
        }
      }
      .mobile-app {
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          margin: 1rem 0rem;
        }
      }
    }

    .carousel-screens {
      margin-top: 4rem;
      margin-bottom: 3rem;

      @media screen and (max-width: 767px) {
        margin-top: 0rem;
        margin-bottom: 2rem;
      }
      .screens {
        padding: 2rem 2rem;
      }
    }
  }
  //smartphone app//
  //marketplace//
  .marketplace {
    background-image: url("../../images/marketplace/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    //padding-top: 4rem;
    padding: 4rem 4rem 4rem 4rem;
    // margin-bottom: 6rem;
    // p {
    //   margin: auto;
    //   width: 60%;
    //   @media screen and (max-width: 1024px) {
    //     width: 100%;
    //   }
    // }
    @media screen and (max-width: 1024px) {
      padding: 2rem 2rem;
    }
    color: $white;
    .react-switch {
      border: 1.49317px solid #e9ecef;
      border-radius: 104.522px;
      padding: 1rem 2rem;
      margin: 2rem 0rem;
      //width: 22rem;
      //height: 5rem;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 767px) {
        border: 1px solid transparent;
      }
      .tablink-active {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // height: 100%;
        // width: 10rem;
        // color: $white;
        // font-size: 1.5rem;
        // margin: 0rem 2rem;
        background: linear-gradient(126.21deg, #6a35ee 0%, #9930ef 14.24%, #5737ee 49.56%, #795ceb 93.2%);
        border-radius: 64.2062px;
        padding: 1rem 2rem;
      }
      .tablink-fixed {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 14rem;
        color: $white;
        font-size: 1.5rem;
        //margin: 0rem 2rem;
        padding: 1rem 2rem;
        //         background: linear-gradient(126.21deg, #6A35EE 0%, #9930EF 14.24%, #5737EE 49.56%, #795CEB 93.2%);
        // border-radius: 64.2062px;
      }
    }
    .slider {
      margin: 2rem 5rem 5rem 5rem;
      @media screen and (max-width: 767px) {
        margin: 0rem 0rem 3rem 0rem;
      }
      .style-ic {
        @media screen and (min-width: 1920px) {
          padding: 0 2rem;
        }
      }
      .icons-content {
        display: flex;
        flex-direction: column;
        .icon-img {
          width: 73%;
        }
      }
      .card {
        margin-right: 1.5rem;
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
        background-color: transparent;
        background: rgba(67, 65, 65, 0.16);
        border: 1px solid $white;
        border-radius: 17.24px;
        .Standard {
          color: $seagreen2;
          font-weight: 600;
        }
        .Premium {
          color: $maroon2;
          font-weight: 600;
        }
        .Super {
          color: $green2;
          font-weight: 600;
        }
        .title-des {
          opacity: 0.6;
          border: 0.5px solid #f9efef;
          border-radius: 1.87627px;
          padding: 5px;
        }
        .card-body {
          text-align: start;
        }
        .card-img-top {
          transform: rotate(15deg);
        }
      }
    }
  }
  //marketplace//
  //servicebox
  .servicebox {
    // background-image: url("../../images/marketplace/bgn.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    //border: 1px solid red;
    //margin-bottom: 3rem;
    .service-text {
      text-align: center;

      color: $white;
      margin: 4rem 5rem;
    }
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 1024px) {
      margin: 2rem;
    }
    p {
      //font-size: 24px;
      text-align: center;
      margin: auto;
      width: 70%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .boxservice {
      margin-bottom: 19rem;
      @media screen and (max-width: 767px) {
        margin-bottom: 10rem;
      }
    }
  }
  //servicebox
  //enagage to earn//
  .engage {
    background-image: url("../../images/engagetoearn/bg1.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 4rem 3rem;
    color: $white;
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    @media screen and (min-width: 768px) {
      aspect-ratio: 1920/1013;
    }

    @media screen and (max-width: 992px) {
      padding: 2rem;
    }
    @media screen and (max-width: 767px) {
      background-image: url("../../images/engagetoearn/mob-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 72rem;
    }
    @media screen and (max-width: 600px) and (min-width: 501px) {
      height: 52rem;
    }
    @media screen and (max-width: 500px) {
      background-image: url("../../images/engagetoearn/mob-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 39rem;
    }
    .content {
      margin: 2rem;
      display: flex;
      justify-content: center;

      .verticalLine {
        border-left: 0.5px solid $white;
        opacity: 0.6;
        margin-left: 4rem;
        margin-right: 4rem;
        //border: 1px solid red;
      }
      .competition {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5rem;
        margin-bottom: 5rem;
        @media screen and (max-width: 992px) {
          margin-top: 0rem;
          margin-bottom: 0rem;
        }
        .card {
          margin-top: 3rem;
          border: 1.09688px solid #ffffff;
          border-radius: 12.7856px;
          margin-right: 4rem;
          margin-left: 4rem;
          width: 30%;
          @media screen and (max-width: 992px) {
            width: 100%;
            margin-right: 0rem;
            margin-left: 0rem;
          }
        }
        .card-body {
          text-align: start;
          .card-text {
            opacity: 0.5;
          }
        }
      }
    }
  }

  //vit//@at-root
  .engage-vit {
    background-image: url("../../images/engagetoearn/web-vit-engage.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 4rem 3rem;
    color: $white;
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    @media screen and (min-width: 768px) {
      aspect-ratio: 1920/1013;
    }

    @media screen and (max-width: 992px) {
      padding: 2rem;
    }
    @media screen and (max-width: 767px) {
      background-image: url("../../images/engagetoearn/mob-vit-engage.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 72rem;
    }
    @media screen and (max-width: 600px) and (min-width: 501px) {
      height: 52rem;
    }
    @media screen and (max-width: 500px) {
      background-image: url("../../images/engagetoearn/mob-vit-engage.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 39rem;
    }
    .content {
      margin: 2rem;
      display: flex;
      justify-content: center;

      .verticalLine {
        border-left: 0.5px solid $white;
        opacity: 0.6;
        margin-left: 4rem;
        margin-right: 4rem;
        //border: 1px solid red;
      }
      .competition {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5rem;
        margin-bottom: 5rem;
        @media screen and (max-width: 992px) {
          margin-top: 0rem;
          margin-bottom: 0rem;
        }
        .card {
          margin-top: 3rem;
          border: 1.09688px solid #ffffff;
          border-radius: 12.7856px;
          margin-right: 4rem;
          margin-left: 4rem;
          width: 30%;
          @media screen and (max-width: 992px) {
            width: 100%;
            margin-right: 0rem;
            margin-left: 0rem;
          }
        }
        .card-body {
          text-align: start;
          .card-text {
            opacity: 0.5;
          }
        }
      }
    }
  }
  //vit//
  //tur//@at-root
  .engage-tur {
    background-image: url("../../images/engagetoearn/web-tur-engage.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 4rem 3rem;
    color: $white;
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    @media screen and (min-width: 768px) {
      aspect-ratio: 1920/1013;
    }

    @media screen and (max-width: 992px) {
      padding: 2rem;
    }
    @media screen and (max-width: 767px) {
      background-image: url("../../images/engagetoearn/mob-tur-engage.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 72rem;
    }
    @media screen and (max-width: 600px) and (min-width: 501px) {
      height: 52rem;
    }
    @media screen and (max-width: 500px) {
      background-image: url("../../images/engagetoearn/mob-tur-engage.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 39rem;
    }
    .content {
      margin: 2rem;
      display: flex;
      justify-content: center;

      .verticalLine {
        border-left: 0.5px solid $white;
        opacity: 0.6;
        margin-left: 4rem;
        margin-right: 4rem;
        //border: 1px solid red;
      }
      .competition {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5rem;
        margin-bottom: 5rem;
        @media screen and (max-width: 992px) {
          margin-top: 0rem;
          margin-bottom: 0rem;
        }
        .card {
          margin-top: 3rem;
          border: 1.09688px solid #ffffff;
          border-radius: 12.7856px;
          margin-right: 4rem;
          margin-left: 4rem;
          width: 30%;
          @media screen and (max-width: 992px) {
            width: 100%;
            margin-right: 0rem;
            margin-left: 0rem;
          }
        }
        .card-body {
          text-align: start;
          .card-text {
            opacity: 0.5;
          }
        }
      }
    }
  }

  //tur//

  //chn//@at-root
  .engage-chn {
    background-image: url("../../images/engagetoearn/web-chn-engage.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 4rem 3rem;
    color: $white;
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    @media screen and (min-width: 768px) {
      aspect-ratio: 1920/1013;
    }

    @media screen and (max-width: 992px) {
      padding: 2rem;
    }
    @media screen and (max-width: 767px) {
      background-image: url("../../images/engagetoearn/mob-chn-engage.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 72rem;
    }
    @media screen and (max-width: 600px) and (min-width: 501px) {
      height: 52rem;
    }
    @media screen and (max-width: 500px) {
      background-image: url("../../images/engagetoearn/mob-chn-engage.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 39rem;
    }
    .content {
      margin: 2rem;
      display: flex;
      justify-content: center;

      .verticalLine {
        border-left: 0.5px solid $white;
        opacity: 0.6;
        margin-left: 4rem;
        margin-right: 4rem;
        //border: 1px solid red;
      }
      .competition {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5rem;
        margin-bottom: 5rem;
        @media screen and (max-width: 992px) {
          margin-top: 0rem;
          margin-bottom: 0rem;
        }
        .card {
          margin-top: 3rem;
          border: 1.09688px solid #ffffff;
          border-radius: 12.7856px;
          margin-right: 4rem;
          margin-left: 4rem;
          width: 30%;
          @media screen and (max-width: 992px) {
            width: 100%;
            margin-right: 0rem;
            margin-left: 0rem;
          }
        }
        .card-body {
          text-align: start;
          .card-text {
            opacity: 0.5;
          }
        }
      }
    }
  }

  //chn//
  //es//@at-root
  .engage-es {
    background-image: url("../../images/engagetoearn/web-es-engage.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 4rem 3rem;
    color: $white;
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    @media screen and (min-width: 768px) {
      aspect-ratio: 1920/1013;
    }

    @media screen and (max-width: 992px) {
      padding: 2rem;
    }
    @media screen and (max-width: 767px) {
      background-image: url("../../images/engagetoearn/mob-es-engage.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 72rem;
    }
    @media screen and (max-width: 600px) and (min-width: 501px) {
      height: 52rem;
    }
    @media screen and (max-width: 500px) {
      background-image: url("../../images/engagetoearn/mob-es-engage.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 39rem;
    }
    .content {
      margin: 2rem;
      display: flex;
      justify-content: center;

      .verticalLine {
        border-left: 0.5px solid $white;
        opacity: 0.6;
        margin-left: 4rem;
        margin-right: 4rem;
        //border: 1px solid red;
      }
      .competition {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5rem;
        margin-bottom: 5rem;
        @media screen and (max-width: 992px) {
          margin-top: 0rem;
          margin-bottom: 0rem;
        }
        .card {
          margin-top: 3rem;
          border: 1.09688px solid #ffffff;
          border-radius: 12.7856px;
          margin-right: 4rem;
          margin-left: 4rem;
          width: 30%;
          @media screen and (max-width: 992px) {
            width: 100%;
            margin-right: 0rem;
            margin-left: 0rem;
          }
        }
        .card-body {
          text-align: start;
          .card-text {
            opacity: 0.5;
          }
        }
      }
    }
  }

  //es//
  //enagage to earn//
  //scavanger
  .hunt {
    // background-image: url("../../images/hunt/hunt.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    text-align: center;
    //padding: 4rem 3rem;
    color: $white;
    width: 100%;
    //height: 58rem;
    // @media screen and (max-width: 768px) {
    //   background-image: url("../../images/hunt/mob.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    // }
    // @media screen and (min-width: 2201px) {
    //   height: 64rem;
    // }
    // @media screen and (max-width: 2200px) and (min-width: 1901px) {
    //   height: 51rem;
    // }
    // @media screen and (max-width: 1900px) and (min-width: 1441px) {
    //   height: 43rem;
    // }
    // @media screen and (max-width: 1440px) and (min-width: 1201px) {
    //   height: 35rem;
    // }
    // @media screen and (max-width: 1200px) and (min-width: 1025px) {
    //   height: 29rem;
    // }
    // @media screen and (max-width: 1024px) and(min-width:881px) {
    //   height: 92rem;
    // }
    // @media screen and (max-width: 880px) and(min-width:768px) {
    //   height: 77rem;
    // }
    // @media screen and (max-width: 767px) {
    //   height: 39rem;
    // }
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 1024px) {
      //padding: 2rem;
      // background-image: url("../../images/hunt/mob.png");
      // background-repeat: no-repeat;
      // background-size: cover;
      //aspect-ratio: 693/428;
    }
    .carousel-text {
      position: absolute;
      //right: auto;
      width: 100%;
      /* bottom: 20px; */
      //left: 15%;
      //z-index: 1001;
      padding-top: 4rem;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
      z-index: 1;
      padding-left: 2rem;
      padding-right: 2rem;
      @media screen and (max-width: 1024px) {
        padding-top: 0rem;
      }
    }
  }
  //scavanger

  //partners//
  .partners {
    text-align: center;
    margin: 4rem 0rem;
    color: $white;

    cursor: pointer;
    @media screen and (max-width: 851px) {
      margin: 2rem 2rem;
    }

    .section-1 {
      margin: 2rem 3rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @media screen and (max-width: 851px) {
        margin: 0rem;
      }
      .section {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 0rem;
        margin-bottom: 0rem;
        @media screen and (max-width: 851px) {
          margin-top: 0rem;
          margin-left: 0rem;
          margin-right: 0rem;
          width: 50%;
        }
        .partner-img {
          width: 80%;
          @media screen and (max-width: 851px) {
            width: 60%;
          }
        }
        .card-title {
          margin-bottom: 0px;
          margin-top: 2rem;
        }
        .card-text {
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }
    }
  }
  //partners//
  //stepmap//@at-root
  .stepmap {
    // background-image: url("../../images/stepmap/ground.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    text-align: center;
    margin: 4rem 0rem;
    color: $white;
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      margin: 2rem 0rem;
    }
    .road-map {
      //margin-top: 6rem;
    }
    .section-1 {
      margin: 2rem 2rem;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      .section {
        padding: 1rem 0rem;
        border: 1px solid transparent;
        width: 15%;
        .card-title {
          //width: 30%;
          margin-top: 1rem;
        }
      }
      .arrow {
        display: flex;
        margin-left: 1rem;
        margin-right: 1rem;
        align-items: center;
        img {
          width: 70px;
        }
      }
    }
  }

  //stepman//
  //Team//@at-root
  .team {
    text-align: center;
    padding: 4rem 5rem;
    color: $white;
    .text-sec-category {
      width: 70%;
      /* margin-left: 8%; */
      margin: auto;
      @media screen and (max-width: 1024px) {
        width: 90%;
      }
      .intro {
        font-size: 33.7809px;
        line-height: 41px;
        letter-spacing: 0.765em;
        color: #fff09d;
        @media screen and (max-width: 1024px) {
          font-size: 20.9788px;
          line-height: 23px;
        }
      }
      .smartphone1 {
        font-style: normal;
        font-weight: 700;
        font-size: 138.249px;
        line-height: 169px;
        text-transform: uppercase;

        color: #ffffff;

        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 90.249px;
          line-height: 86px;
        }
        // @media screen and (min-width: 1025px) and (max-width: 1999px) {
        //   font-size: 100.249px;
        //   line-height: 82px;
        // }

        @media screen and (min-width: 769px) and (max-width: 1439px) {
          font-size: 77.249px;
          line-height: 78px;
        }

        @media screen and (max-width: 768px) {
          font-size: 48.249px;
          line-height: 51px;
        }
        // @media screen and (max-width: 1020px) {
        //   font-size: 24px;
        //   line-height: 29px;
        // }
      }
      .detail {
        font-style: normal;
        font-weight: 500;
        font-size: 23.5856px;
        line-height: 29px;
        text-transform: capitalize;

        color: #ffffff;
        @media screen and (min-width: 601px) and (max-width: 1439px) {
          font-size: 16px;
          line-height: 23px;
        }

        @media screen and (max-width: 600px) {
          font-size: 8.21px;
          line-height: 10px;
        }
      }
    }
    @media screen and (min-width: 769px) and(max-width: 1250px) {
      padding: 4rem 2rem;
    }
    p {
      margin: auto;
      //width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .sub-head {
      //text-align: start;
      font-size: 2.5rem;
    }
    @media screen and (max-width: 768px) {
      padding: 4rem 2rem;
    }
    .slider-team {
      //padding: 0rem 10rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      cursor: pointer;

      @media screen and (min-width: 1600px) {
        //padding: 0rem 10rem;
      }
      .card {
        margin-right: 1.5rem;
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 2rem;
        background-color: transparent;
        background: rgba(67, 65, 65, 0.16);
        //border: 1px solid $white;
        border-radius: 50%;
        width: 18rem;
        //border: 6.12705px solid #D9D9D9;
        // {
        //   padding: 1.25rem;
        // }
        @media screen and (min-width: 769px) and(max-width: 1050px) {
          width: 16rem;
          margin-right: 1rem;
          margin-left: 1rem;
        }
        @media screen and (min-width: 600px) and(max-width: 768px) {
          width: 15rem;
          margin-right: 1rem;
          margin-left: 1rem;
        }
        @media screen and (max-width: 425px) and(min-width: 320px) {
          width: 15rem;
        }
        @media screen and (max-width: 324px) {
          width: 13rem;
        }
        @media screen and (min-width: 1300px) {
          margin-right: 2.5rem;
          margin-left: 2.5rem;
        }
        .card-body {
          padding: 0px;

          .header {
            padding: 1.25rem;
            text-align: start;
            span {
              color: $greensubheading;
            }
          }
          img {
            display: block;
            width: 31rem;
            border-radius: 140.922px;
            object-fit: cover;
            //padding: 1rem;
            //height:15.5rem;
          }

          .thumbnail {
            position: relative;
            display: inline-block;
          }

          .caption {
            position: absolute;
            bottom: 0%;
            left: 0%;
            text-align: center;
            padding: 1rem;
            cursor: pointer;
            @media screen and (max-width: 425px) {
              bottom: 0%;
            }
            p {
              text-align: center;
              color: white;
              font-size: 12px;
              @media screen and (min-width: 1920px) {
                font-size: 14px;
              }
              @media screen and (max-width: 425px) {
                font-size: 10px;
                margin-bottom: 0px;
              }
            }
          }
        }
        .desigination {
          text-align: left;
        }
      }
    }
    .react-switch-wallet {
      //border: 1.49317px solid #e9ecef;
      //border-radius: 104.522px;
      padding: 1rem 0rem;
      margin: 2rem 0rem;
      //width: 22rem;
      //height: 5rem;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 767px) {
        border: 1px solid transparent;
        padding: 0rem;
      }
      .tablink-active {
        border-radius: 4.19px;
        background: linear-gradient(126.21deg, #6a35ee 0%, #9930ef 14.24%, #5737ee 49.56%, #795ceb 93.2%);
        //border-radius: 64.2062px;
        padding: 1rem 2rem;
      }
      .tablink-fixed {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4.19px;
        height: 100%;
        width: 13rem;
        color: $white;
        font-size: 1rem;
        //margin: 0rem 2rem;
        padding: 1rem 1rem;
        // background: rgba(118, 118, 128, 0.12);
        // border-radius: 0.814674px;
        // transform: matrix(1, 0, 0, 1, 0, 0);
        //         background: linear-gradient(126.21deg, #6A35EE 0%, #9930EF 14.24%, #5737EE 49.56%, #795CEB 93.2%);
        // border-radius: 64.2062px;
      }
      .tab-style {
        background: rgba(118, 118, 128, 0.12);
        border-radius: 4.19px;
        transform: matrix(1, 0, 0, 1, 0, 0);
      }
      .tab-icon {
        width: 2rem;
        margin: 0rem 1rem;
      }
    }
  }
  //Team//
  //game sneaker
  .game-shoe {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    //position: relative;
    @media screen and (min-width: 1024px) and (max-width: 1500px) {
      display: flex;
    }
    @media screen and (max-width: 1024px) {
      width: 80%;
      margin-top: 4rem;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  //game sneaker
  //sports
  .sports {
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 4rem 3rem;
    color: $white;

    @media screen and (min-width: 426px) {
      background-image: url("../../images/sports/bg-web.png");
      aspect-ratio: 1920/1008;
    }
    @media screen and (max-width: 425px) {
      background-image: url("../../images/sports/mob.png");
      background-repeat: no-repeat;
      background-size: cover;
      //aspect-ratio: 856/1368;
      height: 43rem;
    }
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
  //sports
  //scolorship

  .scolorship {
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 4rem 3rem;
    color: $white;
    @media screen and (min-width: 426px) {
      aspect-ratio: 1920/842;
      background-image: url("../../images/scolorship/bg.png");
    }
    @media screen and (max-width: 425px) {
      background-image: url("../../images/scolorship/mob.png");
      background-repeat: no-repeat;
      background-size: cover;
      //aspect-ratio: 429/693;
      //aspect-ratio: 856/1098;
      height: 30rem;
    }
    p {
      margin: auto;
      width: 60%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .view {
      margin-top: 4rem;
      color: $black;
      width: 10%;
      border: 1px solid $white;
      font-weight: bold;
      @media screen and (max-width: 1440px) and (min-width: 769px) {
        width: 25%;
      }
      @media screen and (max-width: 768px) {
        width: 60%;
      }
    }
  }
  //scolorship

  //category card deck

  //category card deck
  .card-category {
    display: flex;
    @media screen and (min-width: 993px) and (max-width: 1500px) {
      width: 100%;
    }
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      //width: 75%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .card {
      border: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .card-img-overlay {
      top: 29rem;
      text-align: center;
      @media screen and (max-width: 767px) {
        top: 14rem;
      }
    }
  }
}
