.how-play {
  cursor: pointer;
  .img-num {
    width: 10%;
    margin-bottom: 1rem;
  }
  .main {
    margin-top: 4rem;

    .text-content {
      color: white;
      width: 40%;
      position: absolute;
      left: 10%;
      top: 15%;
      // @media screen and (min-width: 992px) {
      //   top: 8%;
      // }
      @media screen and (max-width: 1439px) {
        top: 8%;
      }
      @media screen and (min-width: 2000px) {
        top: 8%;
      }
      @media screen and (min-width: 1440px) and (max-width: 1999px) {
        top: 10%;
      }
      .howto {
        font-style: normal;
        font-weight: 800;
        font-size: 128.303px;
        //line-height: 243px;
        margin-bottom: 0px;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 88.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 44.6796px;
        }
      }
      .earn {
        color: #ffea2d;
        font-weight: 800;
        font-size: 198.942px;
        margin-bottom: 0px;
        //line-height: 243px;

        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 88.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 44.6796px;
        }
      }
      .detail {
        font-weight: 500;
        font-size: 32px;
      }
    }

    @media screen and (min-width: 451px) {
      background-image: url("../../images/howtoplay/newimages/start.png");
      background-repeat: no-repeat;
      background-size: cover;
      aspect-ratio: 1920/947;
    }

    @media screen and (max-width: 450px) {
      background-image: url("../../images/howtoplay/newimages/mobile/bg1.png");
      //aspect-ratio: 856/1200;
      background-repeat: no-repeat;
      background-size: cover;
      height: 34rem;
    }

    .main-img {
      //position: relative;
      //z-index: 1000;
      //aspect-ratio: 1920/666;
    }
    .main-mob {
      position: relative;
      //z-index: 1000;
      aspect-ratio: 856/1368;
    }
    .content {
      position: absolute;
      color: $white;
      text-align: center;
      //z-index: 1001;
      top: 20%;
      //left: 40%;
      width: 100%;
      margin: 0 auto;
    }
  }
  .step1 {
    //padding: 3rem 5rem;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (min-width: 769px) {
      background-image: url("../../images/howtoplay/newimages/1.png");
      aspect-ratio: 1920/874;
    }
    @media screen and (max-width: 768px) {
      //aspect-ratio: 428/828;
      //aspect-ratio: 428/684;
      //height: 39rem;
      background-image: url("../../images/howtoplay/newimages/mobile/1.png");
    }
    @media screen and (max-width: 599px) {
      height: 39rem;
    }
    @media screen and (min-width: 600px) and (max-width: 768px) {
      height: 75rem;
    }
    .content {
      width: 50%;
      position: absolute;
      top: 10%;
      color: $white;
      left: 10%;
      .up-text {
        font-style: normal;
        font-weight: 700;
        font-size: 93.8185px;

        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 38.6796px;
        }
      }
      .down-text {
        font-weight: 700;
        font-size: 125.914px;
        color: #fff09d;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 38.6796px;
        }
      }
      @media screen and (max-width: 768px) {
        top: 5%;
        width: 90%;
        //border: 1px solid red;
        left: 5%;
      }
      p {
        width: 80%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .step2 {
    //padding: 3rem 5rem;

    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @media screen and (min-width: 769px) {
      background-image: url("../../images/howtoplay/newimages/2.png");
      aspect-ratio: 1920/874;
    }
    @media screen and (max-width: 768px) {
      //aspect-ratio: 856/1368;
      //height: 40rem;
      //aspect-ratio: 428/684;
      //aspect-ratio: 428/600;
      background-image: url("../../images/howtoplay/newimages/mobile/2.png");
    }
    @media screen and (max-width: 599px) {
      height: 39rem;
    }
    @media screen and (min-width: 600px) and (max-width: 768px) {
      height: 75rem;
    }
    .content {
      width: 50%;
      position: absolute;
      top: 10%;
      color: $white;
      left: 10%;
      .up-text {
        font-style: normal;
        font-weight: 700;
        font-size: 93.8185px;

        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 35.6796px;
        }
      }
      .down-text {
        font-weight: 700;
        font-size: 125.914px;
        color: #fff09d;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 35.6796px;
        }
      }
      @media screen and (max-width: 768px) {
        top: 5%;
        width: 90%;
        //border: 1px solid red;
        left: 5%;
      }
      p {
        width: 80%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .step3 {
    //padding: 3rem 5rem;

    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @media screen and (min-width: 769px) {
      background-image: url("../../images/howtoplay/newimages/3.png");
      aspect-ratio: 1920/874;
    }
    @media screen and (max-width: 768px) {
      //aspect-ratio: 856/1368;
      //aspect-ratio: 428/684;
      //height: 40rem;
      background-image: url("../../images/howtoplay/newimages/mobile/3.png");
    }
    @media screen and (max-width: 599px) {
      height: 39rem;
    }
    @media screen and (min-width: 600px) and (max-width: 768px) {
      height: 75rem;
    }
    .content {
      width: 50%;
      position: absolute;
      top: 15%;
      color: $white;
      left: 10%;
      .up-text {
        font-style: normal;
        font-weight: 700;
        font-size: 93.8185px;

        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 35.6796px;
        }
      }
      .down-text {
        font-weight: 700;
        font-size: 125.914px;
        color: #fff09d;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 35.6796px;
        }
      }
      @media screen and (max-width: 768px) {
        top: 5%;
        width: 90%;
        //border: 1px solid red;
        left: 5%;
      }
      @media screen and (max-width: 768px) {
        top: 5%;
        width: 90%;
        //border: 1px solid red;
        left: 5%;
      }
      p {
        width: 80%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .step4 {
    //padding: 3rem 5rem;

    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @media screen and (min-width: 769px) {
      background-image: url("../../images/howtoplay/newimages/4.png");
      aspect-ratio: 1920/874;
    }
    @media screen and (max-width: 768px) {
      //aspect-ratio: 856/1368;
      //height: 40rem;
      //aspect-ratio: 428/684;
      background-image: url("../../images/howtoplay/newimages/mobile/4.png");
    }
    @media screen and (max-width: 599px) {
      height: 39rem;
    }
    @media screen and (min-width: 600px) and (max-width: 768px) {
      height: 75rem;
    }
    .content {
      width: 50%;
      position: absolute;
      top: 10%;
      color: $white;
      left: 10%;
      .up-text {
        font-style: normal;
        font-weight: 700;
        font-size: 93.8185px;

        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 35.6796px;
        }
      }
      .down-text {
        font-weight: 700;
        font-size: 125.914px;
        color: #fff09d;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 35.6796px;
        }
      }
      @media screen and (max-width: 768px) {
        top: 5%;
        width: 90%;
        //border: 1px solid red;
        left: 5%;
      }
      p {
        width: 70%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .step5 {
    //padding: 3rem 5rem;

    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @media screen and (min-width: 769px) {
      background-image: url("../../images/howtoplay/newimages/5.png");
      aspect-ratio: 1920/874;
    }
    @media screen and (max-width: 768px) {
      //aspect-ratio: 856/1368;
      //height: 40rem;
      //aspect-ratio: 428/684;
      background-image: url("../../images/howtoplay/newimages/mobile/5.png");
    }
    @media screen and (max-width: 599px) {
      height: 39rem;
    }
    @media screen and (min-width: 600px) and (max-width: 768px) {
      height: 75rem;
    }
    .content {
      width: 50%;
      position: absolute;
      top: 15%;
      color: $white;
      left: 10%;
      .up-text {
        font-style: normal;
        font-weight: 700;
        font-size: 93.8185px;

        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 35.6796px;
        }
      }
      .down-text {
        font-weight: 700;
        font-size: 125.914px;
        color: #fff09d;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 57.303px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 47.303px;
        }
        @media screen and (max-width: 992px) {
          font-size: 35.6796px;
        }
      }
      @media screen and (max-width: 768px) {
        top: 5%;
        width: 90%;
        //border: 1px solid red;
        left: 5%;
      }
      p {
        width: 80%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}
