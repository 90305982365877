.smart {
  background-image: url("../../images/smarttransfer/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 1920/958;
  text-align: center;
  color: $white;
  //max-width: 2rem 4rem;

  .heading-smart {
    padding: 10rem 5rem 0rem 5rem;
  }
  .smart-form {
    border: 1px solid transparent;
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    @media screen and(min-width:1800px) {
      width: 25%;
    }
    @media screen and (max-width: 1300px) and (min-width: 993px) {
      width: 50%;
    }
    @media screen and (max-width: 992px) and (min-width: 769px) {
        width: 60%;
      }
    @media screen and (max-width: 768px) and (min-width: 501px) {
      width: 77%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    .form-control {
      background-color: transparent !important;
      height: calc(3rem + 2px);
      color: $white;
      font-size: 1rem;
    }
    .email {
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
    .password {
      margin-top: 1rem;

    }
    .form-text {
      text-align: end;
      text-decoration: underline;
    }
    .login {
      width: 100%;
      background: #9630ef;
      border: 1px solid #ffffff;
      border-radius: 2.30018px;
    }
    .socials {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
  
      .social-btn {
        width: 48%;
        font-weight: 600;
        font-size: 12.1412px;
        line-height: 326%;
        color: $black;
        background-color: $white;
        border: 1px solid $white;
        padding: 6px 5px;
  
        @media screen and (max-width: 500px) and (min-width: 361px) {
          font-size: 8.1412px;
        }
        @media screen and (max-width: 360px) {
          font-size: 7.1412px;
        }
  
        img {
          width: 24px;
          padding-right: 4px;
        }
      }
  }

  }
  .text-line
  {
    font-size: 11px;
    span{
      text-decoration: underline;
    }
  }
}
