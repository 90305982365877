.smart-nft {
  background-image: url("../../images/smarttransfer/metaswapbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 1920/958;
  padding: 10rem 3rem;
  color: $white;
  @media screen and (max-width: 768px) {
    padding: 10rem 2rem;
    aspect-ratio: 520/587;
  }
  .smart-sec {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .headerdivider {
      //width: 1px;
      height: auto;
      opacity: 0.3;
      border: 2px solid #ffffff;
    }
    .wallet {
      width: 49%;
      text-align: center;
      .meta {
        margin-top: 15rem;
        background: $white;
        border-radius: 4.19px;
        border: 1px solid $white;
        font-weight: 600;
        img {
          width: 2rem;
        }
      }
      .text {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
      }
      @media screen and (max-width: 768px) {
        width: 98%;
      }
    }
    .app {
      width: 49%;
      text-align: center;
      .text-app {
        margin-top: 15rem;
        color: $white;
      }
      @media screen and (max-width: 768px) {
        width: 98%;
      }
    }
    .react-switch-wallet {
      //border: 1.49317px solid #e9ecef;
      //border-radius: 104.522px;
      padding: 1rem 0rem;
      margin: 2rem 0rem;
      //width: 22rem;
      //height: 5rem;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 767px) {
        border: 1px solid transparent;
        padding: 0rem;
      }
      .tablink-active {
        border-radius: 4.19px;
        background: linear-gradient(
          126.21deg,
          #6a35ee 0%,
          #9930ef 14.24%,
          #5737ee 49.56%,
          #795ceb 93.2%
        );
        //border-radius: 64.2062px;
        padding: 1rem 2rem;
      }
      .tablink-fixed {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4.19px;
        height: 100%;
        width: 13rem;
        color: $white;
        font-size: 1rem;
        //margin: 0rem 2rem;
        padding: 1rem 1rem;
        // background: rgba(118, 118, 128, 0.12);
        // border-radius: 0.814674px;
        // transform: matrix(1, 0, 0, 1, 0, 0);
        //         background: linear-gradient(126.21deg, #6A35EE 0%, #9930EF 14.24%, #5737EE 49.56%, #795CEB 93.2%);
        // border-radius: 64.2062px;
      }
      .tab-style {
        background: rgba(118, 118, 128, 0.12);
        border-radius: 4.19px;
        transform: matrix(1, 0, 0, 1, 0, 0);
      }
      .tab-icon {
        width: 2rem;
        margin: 0rem 1rem;
      }
    }
    .slider {
      margin: 2rem 0rem 5rem 0rem;
      @media screen and (max-width: 767px) {
        margin: 0rem 0rem 3rem 0rem;
      }
      .sneaker-card {
        width: 20rem;
        @media screen and (max-width: 1600px) and(min-width: 1331px) {
          width: 17rem;
        }
        @media screen and (max-width: 1330px) and(min-width: 1025px) {
          width: 15rem;
        }
      }
      .card {
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 1.5rem;

        background: #19191b;
        border: 0.5px solid #ffffff;
        border-radius: 17px;
        .Standard {
          color: $seagreen2;
          font-weight: 600;
        }
        .Premium {
          color: $maroon2;
          font-weight: 600;
        }
        .Super {
          color: $green2;
          font-weight: 600;
        }
        .title-des {
          opacity: 0.6;
          border: 0.5px solid #f9efef;
          border-radius: 1.87627px;
          padding: 5px;
        }
        .card-body {
          text-align: start;
        }
        .style-body {
          padding-bottom: 0px;
        }
        .bottom-body {
          padding-top: 0px;
        }
        .card-img-top {
          transform: rotate(15deg);
        }
        .shoe-img {
          width: 85%;
          margin-left: auto;
          margin-right: auto;
        }
        .btn-card {
          background: #ffffff;
          border-radius: 4.19px;
          width: 100%;
          font-weight: bold;
          font-size: 1rem;
        }
        .pending{
          background: #495057;
          border-radius: 4.19px;
          width: 100%;
          font-weight: bold;
          font-size: 1rem;
          color:  #F0B90B;
        }
       
      }
    }
  }
  .modal-content
  {
    background-color: #19191b;
    border: 1px solid $white;
  }
  .modal-footer {
 
    justify-content: center;}
    .yes-btn
    {
      width: 35%;
      background: linear-gradient(
        126.21deg,
        #6a35ee 0%,
        #9930ef 14.24%,
        #5737ee 49.56%,
        #795ceb 93.2%
      );
    }
    .no-btn{
      width: 35%;
    }
    .info-btn
    {
      box-shadow:none;
    }
}
