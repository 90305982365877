.machinelearning {
  margin-top: 3rem;

  .text1 {
    .main-banner-sship {
      position: relative;
    }
    .text-sec-bg2 {
      top: 2%;
      left: 8%;
      z-index: 1;
      position: absolute;
      border: 1px solid transparent;
      width: 85%;
      text-align: left;

      @media screen and (max-width: 1024px) {
        top: 0%;
        left: 7%;
        width: 90%;
      }
      .work {
        font-style: normal;
        font-weight: 700;
        font-size: 78.2727px;
        line-height: 95px;
        /* identical to box height */

        text-transform: uppercase;

        color: #ffffff;
        margin-bottom: 0px !important;

        //margin-top: -45px;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 68.249px;
          line-height: 75px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 48.249px;
          line-height: 55px;
        }
        @media screen and (max-width: 992px) {
          font-size: 23px;
          line-height: 33px;
        }
      }
      .detail {
        font-style: normal;
        font-weight: 500;
        font-size: 23.5856px;
        line-height: 38px;
        text-transform: capitalize;
        //margin-top: -6px;
        margin: 0;
        color: #ffffff;
        width: 100%;

        @media screen and (min-width: 601px) and (max-width: 1439px) {
          font-size: 16px;
          line-height: 23px;
        }

        @media screen and (max-width: 600px) {
          font-size: 8.21px;
          line-height: 10px;
        }
      }
    }
    .text-sec {
      top: 11%;
      left: 8%;
      z-index: 1;
      position: absolute;
      border: 1px solid transparent;
      width: 85%;
      text-align: left;

      @media screen and (max-width: 1024px) {
        top: 12%;
        left: 7%;
        width: 90%;
      }

      span {
        color: #00ffd1;
      }
      .nft {
        font-style: normal;
        font-weight: 800;
        font-size: 138.249px;
        line-height: 115px;
        /* identical to box height */

        text-transform: uppercase;

        color: #ffffff;
        margin-bottom: 0px !important;

        //margin-top: -45px;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 90.249px;
          line-height: 91px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 71.249px;
          line-height: 71px;
        }
        @media screen and (max-width: 992px) {
          font-size: 43px;
          line-height: 54px;
        }
      }
      .nft2 {
        font-style: normal;
        font-weight: 800;
        font-size: 100.249px;
        line-height: 115px;
        /* identical to box height */

        text-transform: uppercase;

        color: #ffffff;
        margin-bottom: 0px !important;

        //margin-top: -45px;
        @media screen and (min-width: 1440px) and (max-width: 1999px) {
          font-size: 73.249px;
          line-height: 91px;
        }

        @media screen and (min-width: 993px) and (max-width: 1439px) {
          font-size: 51.249px;
          line-height: 71px;
        }
        @media screen and (max-width: 992px) {
          font-size: 23px;
          line-height: 54px;
        }
      }

      .detail {
        font-style: normal;
        font-weight: 500;
        font-size: 23.5856px;
        line-height: 38px;
        text-transform: capitalize;
        //margin-top: -6px;
        margin: 0;
        color: #ffffff;

        @media screen and (min-width: 601px) and (max-width: 1439px) {
          font-size: 16px;
          line-height: 23px;
        }

        @media screen and (max-width: 600px) {
          font-size: 8.21px;
          line-height: 10px;
        }
      }
    }
  }
  .sc-img {
    aspect-ratio: 1920/800;
    position: relative;
    @media screen and (max-width: 769px) {
      aspect-ratio: 856/1098;
    }
  }
  .sc-content {
    position: absolute;
    color: $white;
    top: 20%;
    text-align: center;
    width: 100%;
  }
  p {
    margin: auto;
    width: 60%;
    @media screen and (max-width: 1440px) and (min-width: 1025px) {
      width: 90%;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .flow {
    position: relative;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 426px) {
      // background-image: url("../../images/scolorship/flowweb.png");
      // aspect-ratio: 3840/3256;
    }
    @media screen and (max-width: 425px) {
      // background-image: url("../../images/scolorship/flowmobile.png");
      // background-repeat: no-repeat;
      // background-size: cover;
      height: 30rem;
      aspect-ratio: 856/1080;
    }

    .text {
      position: absolute;
      //margin: 3rem 5rem 1rem 5rem;
      text-align: center;
      color: $white;
      top: 4%;
      width: 100%;
      //z-index: 1001;
    }
  }
  .flow-tur {
    position: relative;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 426px) {
      // background-image: url("../../images/scolorship/flow-tur.png");
      // aspect-ratio: 3840/3256;
    }
    @media screen and (max-width: 425px) {
      // background-image: url("../../images/scolorship/mob-tur.png");
      // background-repeat: no-repeat;
      // background-size: cover;
      height: 30rem;
      aspect-ratio: 856/1080;
    }

    .text {
      position: absolute;
      //margin: 3rem 5rem 1rem 5rem;
      text-align: center;
      color: $white;
      top: 4%;
      width: 100%;
      //z-index: 1001;
    }
  }
  .flow-es {
    position: relative;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 426px) {
      // background-image: url("../../images/scolorship/flow-es-web.png");
      // aspect-ratio: 3840/3256;
    }
    @media screen and (max-width: 425px) {
      // background-image: url("../../images/scolorship/mob-spanish.png");
      // background-repeat: no-repeat;
      // background-size: cover;
      height: 30rem;
      aspect-ratio: 856/1080;
    }

    .text {
      position: absolute;
      //margin: 3rem 5rem 1rem 5rem;
      text-align: center;
      color: $white;
      top: 4%;
      width: 100%;
      //z-index: 1001;
    }
  }
  .flow-chn {
    position: relative;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 426px) {
      // background-image: url("../../images/scolorship/chinese.png");
      // aspect-ratio: 3840/3256;
    }
    @media screen and (max-width: 425px) {
      // background-image: url("../../images/scolorship/chinese.png");
      // background-repeat: no-repeat;
      // background-size: cover;
      height: 30rem;
      aspect-ratio: 856/1080;
    }

    .text {
      position: absolute;
      //margin: 3rem 5rem 1rem 5rem;
      text-align: center;
      color: $white;
      top: 4%;
      width: 100%;
      //z-index: 1001;
    }
  }
  .flow-vit {
    position: relative;

    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 426px) {
      // background-image: url("../../images/scolorship/vietnam.png");
      // aspect-ratio: 3840/3256;
    }
    @media screen and (max-width: 425px) {
      // background-image: url("../../images/scolorship/vietnam.png");
      // background-repeat: no-repeat;
      // background-size: cover;
      height: 30rem;
      aspect-ratio: 856/1080;
    }

    .text {
      position: absolute;
      //margin: 3rem 5rem 1rem 5rem;
      text-align: center;
      color: $white;
      top: 4%;
      width: 100%;
      //z-index: 1001;
    }
  }
}
