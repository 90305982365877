.modal-style {
  .top-content {
    padding-left: 3rem;
    padding-top: 1rem;
  }
  .sneaker-cross-pop
  {
    position: absolute;
    left: 98%; 
    top: -1%;
   //width: 3rem;
    @media screen and(max-width: 768px)and(min-width:376px) {
     left: 98%; 
     top: -0.6%;
    width: 3rem;
    }
    @media screen and(max-width: 375px) {
      left: 90%; 
      top: -0.6%;
     width: 3rem;
     }


  }
  
}
.modal-style-android {
  .heading
  {
    text-align: center;
  }
  .top-content {
    padding-left: 3rem;
    padding-top: 1rem;
  }
  .sneaker-cross-pop
  {
    position: absolute;
    left: 98%; 
    top: -1%;
   //width: 3rem;
    @media screen and(max-width: 768px)and(min-width:376px) {
     left: 98%; 
     top: -0.6%;
    width: 3rem;
    }
    @media screen and(max-width: 375px) {
      left: 90%; 
      top: -0.6%;
     width: 3rem;
     }


  }
  
}
.slider-part {
  //padding: 3rem;
  //border: 3px solid red;
  padding: 1rem 3rem !important;
  .head-ssp {
    font-style: normal;
    font-weight: 500;
    font-size: 20.6397px;
    line-height: 18px;
    /* identical to box height */
    margin: 2rem 0.5rem;

    letter-spacing: 0.575em;
  }
  .slick-prev:before {
    margin-left: 0em !important;
  }
  @media screen and(max-width: 768px) {
    padding: 1rem 1rem !important;
  }
  .slider-sneaker {
    //padding: 3rem!important;
    //display: flex;
    //justify-content: center;
  }

  h3 {
    margin: 1rem auto;
  }
 

  .sneaker-card {
    width: 20rem !important;
    padding: 1rem;
    background: #0e0d0d;
    border: 0.577427px solid #505050;
    border-radius: 11.5485px;

    

    @media screen and(max-width: 768px) {
      width: 18rem !important;
    }
    .footer-sneaker {
      // border: 1px solid grey;
      display: flex;
      justify-content: space-between;
    }

    .slick-prev:before {
      margin-left: 0 !important;
    }
  }
}
