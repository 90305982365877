.error {
  //margin-top: 10rem;
  //margin-bottom: 10rem;
  color: $white;
  background-image: url(../../images/error/404bg.png);
  //height: 400px;
  aspect-ratio: 1920/1080;
  @media screen and (max-width: 767px) {
    background-image: url(../../images/error/mob-bg.png);
  }
  .page_404 {
    display: flex;
    justify-content: center;
    padding-top: 15rem;
    @media screen and (max-width: 767px) {
      padding: 5rem;
    }
  }

  .four_zero_four_bg {
    margin-right: 5rem;
    justify-content: center;
    @media screen and (max-width: 767px) {
      margin-right: 0rem;
    }
  }

  .four_zero_four_bg h1 {
    font-size: 8rem;
    color: $white;
  }

  .four_zero_four_bg h3 {
    font-size: 4rem;
  }

  .button-info {
    display: flex;
    justify-content: center;
    .link_404 {
      color: #fff !important;
      padding: 10px 20px;
      background: $pink;
      margin: 20px 0;
      display: inline-block;
      text-align: center;
    }
  }

  // .contant_box_404 {
  //   margin-top: -50px;
  // }
}
// .slider
// {
//   color: white;
//   display: flex;
//   width: 100%;
//   margin-top: 5rem;
//   .sec-1
//   {
//     border: 3px solid green;
//     width: 50%;
//     border-bottom-right-radius: 100%;
//   }
//   .sec-2
//   {
//     border: 3px solid red;
//     width: 50%;
//     border-bottom-left-radius: 100%;
//   }
// }